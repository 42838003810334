import React from 'react';

const Creator = ({ iconColor, ...props }) => (
  <svg
  {...props}
  viewBox='0 0 217 305'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
>
  <path
      d='M162.6 203.799C158.6 202.999 154.2 205.499 150.7 207.399L150.5 207.499C141.2 212.799 117.3 226.499 131 234.199C133.4 235.299 137.2 234.699 139.4 235.799C140.8 236.499 141 238.099 140.1 239.599C138.2 242.499 134 243.099 130.7 243.299C116.6 244.099 103 241.299 90.0001 241.399C77.1001 241.599 64.1001 243.299 65.0001 225.999C66.2001 200.899 62.3001 196.999 77.3001 174.999C82.8001 166.699 87.6001 152.699 76.7001 146.799C72.8001 144.999 67.7001 144.099 66.0001 139.599C63.4001 132.799 64.3001 125.099 64.0001 117.899C63.4001 108.699 62.1001 96.5994 72.4001 92.2994C85.4001 87.3994 99.9001 89.4994 113.5 89.0994C125.5 89.6994 143.5 86.5994 143.3 103.699C143.2 111.299 144.9 120.299 140.3 126.599C138.2 129.499 134.7 131.199 131.6 132.899C124.1 137.299 116 141.399 110.5 148.199C107.1 153.099 107.4 159.499 111.8 162.499C119.2 166.899 128.4 162.199 135.4 159.099C145 153.399 163.8 146.999 167.6 136.299C169.9 129.399 163.2 124.199 161.3 118.199C158 107.499 161.4 94.8994 158.1 83.8994C153.8 69.8994 136.5 70.0994 124.4 69.6994C90.8001 70.3994 56.6001 61.0994 54.7001 107.999C53.6001 121.599 54.4001 135.199 54.6001 148.799C54.2001 153.299 52.2001 157.299 49.4001 160.899C19.8001 192.899 22.2001 238.499 24.5001 279.699C24.5001 292.199 28.2001 303.799 42.6001 303.899C73.9001 304.699 105.1 305.199 136.4 303.699C147 302.299 143.7 289.899 144.1 282.299C144.1 272.099 144.4 261.699 148.2 252.099C149.4 248.599 151 245.199 152.1 241.599C153.9 236.199 154.2 230.199 157.4 225.499C160.1 221.399 164.9 218.699 166.7 214.099C168.7 210.299 167.3 204.699 162.6 203.799Z'
      fill={iconColor}
  />
  <path
      d='M166.1 149.5C155.6 148.7 146.6 157.9 137.6 162.6C130.4 167.2 119.1 170.9 118.1 180.7V180.9C118.3 188.5 127.6 188.2 133 186.3C143.3 183.2 169.7 173.4 172.6 162.6C174.3 157.4 172.2 150.1 166.1 149.5Z'
      fill={iconColor}
  />
  <path
      d='M163.6 177.5C155.2 178.2 148 183.6 140.7 187.5C133.4 191.8 122.4 195.7 121.6 205.5V205.7C122.1 214.4 132 212 137.4 209.6C141.9 207.7 146.4 205.9 150.8 203.7C155.2 201.4 159.4 198.8 163.6 196.2C166.6 194.4 169.6 192.4 171.1 189.1C174 183 170.5 176.8 163.6 177.5Z'
      fill={iconColor}
  />
  <path
      d='M61.8002 49.2007C57.5002 42.3007 50.8002 37.0007 45.2002 31.0007C40.3002 25.1007 33.9002 25.6007 33.7002 34.2007L57.8002 57.0007C63.1002 57.1007 63.9002 53.2007 61.8002 49.2007Z'
      fill={iconColor}
  />
  <path
      d='M112.9 40.7998H113.1C117.6 39.7998 117.3 33.6998 117.4 30.0998C117.5 23.5998 117.7 16.6998 117.5 9.99984C117.4 7.29984 117.2 4.19984 115.4 2.09984C113.3 -0.500162 109.4 -0.200161 108.1 2.99984C107.1 5.09984 107 7.49984 107 9.79984C106.9 14.3998 107.1 19.3998 106.9 23.6998C107 28.7998 105.6 40.5998 112.9 40.7998Z'
      fill={iconColor}
  />
  <path
      d='M215.4 94.1008C213.5 91.9008 210.1 91.9008 207.4 92.0008C200.3 92.5008 193.2 93.1008 186.1 94.1008C182.4 94.6008 176.9 95.7008 176.8 100.201C178 109.201 192.8 103.601 198.6 103.801H198.8C203.3 103.401 209.3 103.301 213.6 100.901C216.1 99.5008 217.3 96.3008 215.4 94.1008Z'
      fill={iconColor}
  />
  <path
      d='M169.9 52.9003C172.9 51.2003 175.2 48.7003 177.7 46.4003C181.1 43.0003 184.7 39.8003 188.1 36.3003C190 34.3003 192.4 31.8003 192.3 28.9003V28.7003C191.8 24.4003 186.6 24.5003 183.8 26.8003C178.1 31.0003 173.2 36.4003 167.9 41.1003C165.8 43.1003 163.6 45.5003 162.8 48.3003C161.3 53.3003 166.1 55.2003 169.9 52.9003Z'
      fill={iconColor}
  />
  <path
      d='M33.3001 107.3C36.3001 105.2 34.4001 100.4 31.7001 98.7C27.4001 95.9 20.0001 95.9 14.2001 96C10.1001 96.3 4.8001 95.2 1.6001 98.3C0.300099 99.6 0.100097 101.7 1.2001 103.1C4.0001 106.5 10.0001 106.2 13.9001 106.8L26.6001 107.8C28.9001 108.1 31.6001 108.3 33.3001 107.3Z'
      fill={iconColor}
  />
  <path
      d='M101.9 231.299C106.7 235.099 113 230.499 113.2 225.099V224.899C113.6 219.399 106.3 215.099 101.8 218.499C97.8002 221.399 97.7001 228.299 101.9 231.299Z'
      fill={iconColor}
  />
  <path
      d='M112.2 106.1C114.3 105.9 116.6 105.5 118.2 104.1C120.1 102.6 120.1 99.5003 117.8 98.5003C116.2 97.7003 114.1 97.8003 112.1 98.0003C106.5 98.5003 100.6 98.0003 95.1 98.1003H94.9C89.1 97.9003 86.3 103.1 92.6 105.4C94.4 105.9 96.3 105.9 98.1 106C102.9 105.9 107.4 106.4 112.2 106.1Z'
      fill={iconColor}
  />
</svg>
);

Creator.defaultProps = {
  iconColor: 'white',
  width: '64',
  height: '64'
};

export default Creator;

import React, { useEffect } from 'react';
import { ThemeProvider, Box } from '@mui/material';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import './App.css';
import AppRoutes from './routes';
import { IApp, IState } from './shared/utilities/interfaces';
import { AuthRoles } from './core/constants';
import AppTheme from './core/theme/appTheme';
import Header from './core/components/header';
import Footer from './core/components/footer';
import Loader from './core/components/appLoader';
import Alert from 'core/components/alert';
import { AccountStatusEnum } from 'features/auth/utilites';
import { history } from './store/store';
 
interface IRootApp {
	app: IApp;
	accountType: string;
	isLoggedIn: boolean;
	searchLoading: boolean;
	accountStatus: string;
}

const RootApp = (rootProps: IRootApp) => {
	const {
		app: {
			isLoading,
			isShowingModal
		},
		isLoggedIn,
		accountType,
		searchLoading,
		accountStatus
	} = rootProps;
	const theme = AppTheme();
	const currentPath = history.location.pathname;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentPath]);
	const fulidContain = currentPath === '/dashboard' && accountType === AuthRoles.CREATOR 
	|| (currentPath.includes('/creator-profile') && !currentPath.includes('edit') && !currentPath.includes('add'))
	|| (currentPath.includes('/brand-profile') && !currentPath.includes('edit')) 
	|| currentPath.includes('/auth')
	|| currentPath.includes('/terms-and-conditions')
	|| currentPath.includes('/community-guidelines')
	|| currentPath.includes('/creator-opportunities/details')
	|| currentPath.includes('/creator-perks/details')
	|| currentPath.includes('/do-not-sell-my-info')
	|| currentPath.includes('/privacy-policy') ? false : true;
	return (
		<ThemeProvider theme={theme}>
			{isLoading && !searchLoading ? <Loader /> : null}
			{isShowingModal && <Alert />}
			<Helmet>
				<title>Clara</title>
			</Helmet>
			<Header />
			<Box sx={{
				'@media (max-width: 1384px)' : {
					padding: fulidContain ? '1.5rem' : 0
				},
				margin: fulidContain ? 'auto' : 0,
				maxWidth: fulidContain ? '1366px' : '100%',
				minHeight: 'calc(100vh - (4rem + 41px))',
				mt: (isLoggedIn && accountStatus === AccountStatusEnum.APPROVED) ? '4rem' : ''
			}}>
				<AppRoutes />
			</Box>
			{currentPath === '/dashboard' && accountType === AuthRoles.CREATOR ? null : <Footer/>}
		</ThemeProvider>
	);
};

export default connect((state: IState) => ({
	app: state.app,
	accountType: state.auth.accountType,
	isLoggedIn: state.auth.isLoggedIn,
	accountStatus: state.auth.status,
	searchLoading: state.creatorBrands.searchLoading
}))(RootApp);

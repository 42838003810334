import React from 'react';

const Pinterest = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M24.5193 0.046875C11.2646 0.046875 0.519531 10.7922 0.519531 24.0467C0.519531 34.2144 6.84531 42.9065 15.7733 46.4033C15.5633 44.5044 15.3738 41.5841 15.8567 39.511C16.2926 37.6381 18.6708 27.5815 18.6708 27.5815C18.6708 27.5815 17.9528 26.144 17.9528 24.0183C17.9528 20.6812 19.8871 18.1898 22.2956 18.1898C24.343 18.1898 25.3323 19.7272 25.3323 21.5705C25.3323 23.6298 24.0212 26.7083 23.3445 29.5613C22.7792 31.9502 24.5425 33.8982 26.8984 33.8982C31.1643 33.8982 34.4432 29.4005 34.4432 22.908C34.4432 17.1618 30.3139 13.144 24.4183 13.144C17.5894 13.144 13.5811 18.266 13.5811 23.5592C13.5811 25.6221 14.3756 27.8338 15.3672 29.0362C15.5631 29.2738 15.592 29.4822 15.5337 29.7246C15.3513 30.4827 14.9466 32.1128 14.867 32.4463C14.7623 32.8856 14.5191 32.9789 14.0644 32.7673C11.0669 31.3719 9.19291 26.9898 9.19291 23.4698C9.19291 15.899 14.6934 8.94647 25.0505 8.94647C33.3761 8.94647 39.8462 14.8789 39.8462 22.8074C39.8462 31.0789 34.631 37.7354 27.3923 37.7354C24.9604 37.7354 22.6741 36.472 21.8916 34.9797C21.8916 34.9797 20.6881 39.562 20.3963 40.685C19.8542 42.7697 18.3918 45.3827 17.4134 46.9765C19.6592 47.6719 22.0455 48.0468 24.5193 48.0468C37.7746 48.0468 48.5196 37.3019 48.5196 24.0467C48.5196 10.7922 37.7746 0.046875 24.5193 0.046875Z"
        fill={iconColor}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="48" height="48" fill="white" transform="translate(0.519531)" />
      </clipPath>
    </defs>
  </svg>
);

Pinterest.defaultProps = {
  iconColor: '#1A1A1AFF',
  width: '48',
  height: '48'
};

export default Pinterest;
import { SessionKeys } from '../../../../core/constants';
import { getFromSession } from '../../../../shared/helpers/auth.helper';
import axiosInstance from '../../../../core/network';

import {
  CreatorProfileImage,
  CreatorPortfolioImage,
  AddPortfolioFormBody,
} from '../utilites/index';
import { ICurrentUser } from '../../../../shared/utilities/interfaces';
import { AxiosResponse } from 'axios';

export function updateSingleCreator(role: string, userId: string, body) {
  for (let i = 0; i < body.socialMedia.length; i += 1) {
    if (
      // body.socialMedia[i].userName.length === 0 ||
      body.socialMedia[i].link.length === 0 ||
      body.socialMedia[i].noOfFollowers.length === 0
    ) {
      body.socialMedia.splice(i, 1);
      i -= 1;
    }
  }
  return axiosInstance.put(`/users/${role}/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}

export function uploadProfileImage(userId: string, body: CreatorProfileImage) {
  return axiosInstance.post(`/users/${userId}/image`, body, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}

export function getCountries() {
  return axiosInstance.get(`/public/country`, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}

export function addPortfolioRequest(
  userId: string,
  body: AddPortfolioFormBody
) {
  return axiosInstance.post(`/users/${userId}/portfolio`, body, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}
export function editPortfolioRequest(
  userId: string,
  portfolioId: string,
  body: ICurrentUser
) {
  return axiosInstance.put(`/users/${userId}/portfolio/${portfolioId}`, body, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}
export function uploadPortfolioImage(
  userId: string,
  body: CreatorPortfolioImage
) {
  return axiosInstance.post(`/users/${userId}/portfolio/image`, body, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}

export function getSingleCreator(userId: string) {
  return axiosInstance.get(`/users/${userId}`, {
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}

export function getOtherCreatorProfle(userId: string, searchUserID?: string) {
  if (userId && searchUserID) {
    return axiosInstance.get(`/users/${userId}/?id=${searchUserID}`, {
      headers: {
        Authorization: `Bearer ${
          getFromSession(SessionKeys.accessToken).result
        }`,
      },
    });
  } else {
    return axiosInstance.get(`/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${
          getFromSession(SessionKeys.accessToken).result
        }`,
      },
    });
  }
}
export function getPortfolioByID(userId: string, portfolioId?: string) {
  if (userId && portfolioId) {
    return axiosInstance.get(`/users/${userId}/portfolio/${portfolioId}`, {
      headers: {
        Authorization: `Bearer ${
          getFromSession(SessionKeys.accessToken).result
        }`,
      },
    });
  }
}

export function deleteProfileRequest(
  userId: string,
): Promise<AxiosResponse> {
  return axiosInstance.delete(`/users/${userId}`,{
    headers: {
      Authorization: `Bearer ${getFromSession(SessionKeys.accessToken).result}`,
    },
  });
}
import { AxiosResponse } from 'axios';
import axiosInstance from 'core/network';
import { IApplyOpportunityData } from 'shared/utilities/interfaces';

export function getCreatorOpportunityRequest(
    userId: string,
    opportunityId: string,
  ): Promise<AxiosResponse<any>>  {
    return axiosInstance.get(`/opportunity/${userId}/${opportunityId}`);
  };


  export function applyOpportunityRequest(
    userId: string,
    opportunityId: string,
    data: IApplyOpportunityData,
  ): Promise<AxiosResponse<any>>  {
    return axiosInstance.post(`/opportunity/${userId}/apply/${opportunityId}`,data);
  };
import { IApp, ReducerActionProps } from '../../../shared/utilities/interfaces';
import { CORE_ACTIONS } from './actions';

const initialState: IApp = {
  isShowingModal: false,
  info: {},
  themeMode: 'light',
  isLoading: false,
  modalData: {
    message: '',
    severity: 'success',
    alertTitle: '',
    strongText: ''
  }
};

const CoreReducer = (state = initialState, action: ReducerActionProps) => {
  switch (action.type) {
    case CORE_ACTIONS.ENABLE_LOADER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CORE_ACTIONS.DISABLE_LOADER: {
      return {
        ...state,
        isLoading: false
      };
    }

    case CORE_ACTIONS.TOGGLE_APP_THEME:
      return {
        ...state,
        themeMode: action.payload.themeMode
      };

    case CORE_ACTIONS.ENABLE_MODAL:
      return {
        ...state,
        isShowingModal: true,
        modalData: action.payload
      };

    case CORE_ACTIONS.DISABLE_MODAL:
      return {
        ...state,
        isShowingModal: false,
        modalData: {
          message: '',
          severity: 'success',
          alertTitle: '',
          strongText: ''
        }
      };

    default: {
      return {
        ...state
      };
    }
  }
};

export default CoreReducer;

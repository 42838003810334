import { connectRouter } from 'connected-react-router';
import { AnyAction, combineReducers } from 'redux';
import { IState } from '../shared/utilities/interfaces';
import Profile from '../features/creator/profile/store/reducers';
import Auth from '../features/auth/store/reducers';
import Core from '../core/store/app/reducers';
import AdminDashboard from '../features/admin/dashboard/store/reducers';
import CreatorDashboard from '../features/creator/dashboard/store/reducers';
import CreatorBrands from '../features/creator/brands/store/reducers';
import AdminBrandList from '../features/admin/brandList/store/reducers';
import AdminReviews from '../features/admin/reviews/store/reducers';
import { AUTH_ACTIONS } from 'features/auth/store/actions';
import creatorOpportunity from 'features/creator/opportunites/store/reducers';
import creatorPerkReducer from 'features/creator/perks/store/reducers';
// HYGEN-IMPORT

const combinedReducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    creatorProfile: Profile,
    auth: Auth,
    app: Core,
    adminDashboard: AdminDashboard,
    creatorDashboard: CreatorDashboard,
    creatorBrands: CreatorBrands,
    creatorsOpportunity: creatorOpportunity,
    creatorsPerk: creatorPerkReducer,
    adminBrandList: AdminBrandList,
    adminReviews: AdminReviews,
  });// HYGEN-ADD-REDUCER
  // please do not remove this and above comments;
  // they are used by hygen for template generation

const createRootReducer =
  (history: any) => (state: IState, action: AnyAction) => {
    // Reset session state once user logout
    if (action.type === AUTH_ACTIONS.LOGOUT_REQUEST) {
      state = {} as IState;
    }
    return combinedReducers(history)(state, action);
  };

export default createRootReducer;

import React, { useEffect } from 'react';
import { AlertProps, AlertTitle } from '@mui/material';
import { Warning, CheckBox } from '@mui/icons-material';
import { connect } from 'react-redux';

import { undefinedArray } from '../../../shared/helpers/auth.helper';
import { IModalData, IState } from '../../../shared/utilities/interfaces';
import { closeModal } from '../../store/app/actions';
import { AlertContainer, StyledAlert } from './index.styles';

interface IAlert extends AlertProps {
  modalData: IModalData;
  closeAlert: () => void;
}

const titleObj = {
  error:"Error!",
  success:"Success!",
  info:"Info!",
  warning:"Warning!"
};

const getMessageList = (messages: string[] | string) => {
  if (messages instanceof Array) {
    return (
      <ul>
        {
          messages.map(message => (
            <li key={message}>{message}</li>
          ))
        }
      </ul>
    );
  }
  else {
    return (
      <>
        {messages}
      </>
    );
  }
};

const getAlertTitle = (severity,title) =>{
  if(title) {
    return title + '!';
  } else {
    return titleObj[severity];
  }
};

function Alert(alertComponentProps: IAlert) {
  const { modalData, closeAlert } = alertComponentProps;
  const {
    message,
    severity,
    alertTitle,
    strongText
  } = modalData;
  const getStrongText = () => {
    if (undefinedArray.indexOf(strongText) === -1) {
      return <> - <strong>{strongText}</strong></>;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      closeAlert();
    }, 7000);
  }, []);

  return (
    <AlertContainer>
      <StyledAlert
        severity={severity}
        onClose={closeAlert}
        iconMapping={{
          error: <Warning />,
          success: <CheckBox />
        }}
      >
        <AlertTitle style={{fontWeight:'bolder'}}>{getAlertTitle(severity,alertTitle)}</AlertTitle>
        {getMessageList(message)}
        <br />{getStrongText()}
      </StyledAlert>
    </AlertContainer>
  );
};

export default connect(
  (state: IState) => ({
    modalData: state.app.modalData
  }),
  ({
    closeAlert: closeModal
  })
)(Alert);

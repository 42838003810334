import React from 'react';

const Tiktok = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M24.8672 0C11.6123 0 0.867188 10.7452 0.867188 24C0.867188 37.2548 11.6123 48 24.8672 48C38.122 48 48.8672 37.2548 48.8672 24C48.8672 10.7452 38.122 0 24.8672 0ZM37.0191 21.7858C34.4706 21.7858 31.9909 20.9587 30.0816 19.5592L30.0664 29.0749C30.0648 30.8381 29.5255 32.5589 28.5205 34.0076C27.5155 35.4564 26.0926 36.5642 24.4417 37.1832C22.7907 37.8022 20.9902 37.903 19.2804 37.4722C17.5707 37.0413 16.033 36.0993 14.8726 34.7718C13.7121 33.4443 12.9841 31.7944 12.7857 30.0424C12.5873 28.2904 12.928 26.5196 13.7622 24.9662C14.5964 23.4128 15.8845 22.1508 17.4546 21.3485C19.0247 20.5462 20.8021 20.2419 22.5497 20.476V25.149C21.7253 24.9055 20.8452 24.9277 20.0342 25.2124C19.2231 25.4972 18.5223 26.0301 18.0312 26.7356C17.54 27.441 17.2834 28.2832 17.2978 29.1427C17.3122 30.0021 17.5969 30.8353 18.1114 31.5239C18.6259 32.2125 19.3441 32.7216 20.1642 32.9791C20.9844 33.2365 21.8647 33.2293 22.6805 32.9582C23.4962 32.6872 24.2059 32.1663 24.709 31.4692C25.212 30.7722 25.4828 29.9345 25.4829 29.0749V10.25H30.29C30.29 11.1336 30.464 12.0086 30.8022 12.825C31.1403 13.6413 31.636 14.3831 32.2608 15.0079C32.8856 15.6327 33.6274 16.1284 34.4438 16.4665C35.2602 16.8047 36.1351 16.9787 37.0188 16.9787L37.0191 21.7858Z" fill={iconColor} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="48" height="48" fill="white" transform="translate(0.867188)" />
      </clipPath>
    </defs>
  </svg>

);

Tiktok.defaultProps = {
  iconColor: '#1A1A1AFF',
  width: '48',
  height: '48'
};

export default Tiktok;
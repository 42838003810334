import { AxiosResponse } from 'axios';

import { 
  assignUserAPI, 
  getCreatorList, 
  updateUserStatusAPI, 
  deleteReviewAPI, 
  assignReviewAPI,
  exportCreatorAPI,
  getOpportunityAPI,
  updatePinStatusApi,
  uploadOpportunityImage,
  addOpportunityRequest,
  uploadPerkImage,
  addPerkRequest,
  getSinglePerkRequest,
  updatePerkRequest,
  updatePerkPinStatusApi,
  getPerksAPI,
  getSingleApplicant,
  getSingleOpportunityRequest,
  updateOpportunityRequest,
  getApplicantList,
  exportOpportunityApplicantsAPI
} from './api';
import { AppDispatch, history } from '../../../../store/store';
import { CREATOR_ACTIONS } from '../../../creator/brands/store/actions';
import { getAlertType } from '../../../../shared/helpers';
import { ActionDispatchType } from '../../../../shared/utilities/interfaces';
import { CORE_ACTIONS } from '../../../../core/store/app/actions';
import { getBrandListAPI } from 'features/admin/dashboard/store/api';
import { getReviewsAPI, updateReviewStatusAPI } from 'features/admin/dashboard/store/api';
import { getFromSession } from 'shared/helpers/auth.helper';
import { SessionKeys } from 'core/constants';

export const GET_ADMIN_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_CREATOR_LIST = 'GET_CREATOR_LIST';
export const GET_CREATOR_LIST_FAILURE = 'GET_CREATOR_LIST_FAILURE';
export const SET_USERS_COUNT = 'SET_USERS_COUNT';
export const GET_BRAND_LIST = 'GET_BRAND_LIST';
export const SET_BRAND_COUNT = 'SET_BRAND_COUNT';
export const GET_BRAND_LIST_FAILURE = 'GET_CREATOR_LIST_FAILURE';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const UPDATE_PIN_STATUS = 'UPDATE_PIN_STATUS';
export const GET_REVIEW_LIST = 'GET_REVIEW_LIST';
export const SET_REVIEW_COUNT = 'SET_REVIEW_COUNT';
export const GET_REVIEW_LIST_FAILURE = 'GET_REVIEW_LIST_FAILURE';
export const GET_OPPORTUNITY_LIST = 'GET_OPPORTUNITY_LIST';
export const SET_OPPORTUNITY_COUNT = 'SET_OPPORTUNITY_COUNT';
export const GET_OPPORTUNITY_LIST_FAILURE = 'GET_OPPORTUNITY_LIST_FAILURE';
export const GET_PERKS_LIST = 'GET_PERKS_LIST';
export const SET_PERKS_COUNT = 'SET_PERKS_COUNT';
export const GET_PERKS_LIST_FAILURE = 'GET_PERKS_LIST_FAILURE';
export const ASSIGN_THE_BRAND = 'ASSIGN_THE_BRAND';
export const GET_OPPORTUNITY_APPLICANTS = 'GET_OPPORTUNITY_APPLICANTS';
export const GET_OPPORTUNITY_APPLICANTS_FAILURE = 'GET_OPPORTUNITY_APPLICANTS_FAILURE';
export const SET_OPPORTUNITY_IMAGE_SUCCESS = 'SET_OPPORTUNITY_IMAGE_SUCCESS';
export const RESET_OPPORTUNITY_IMAGE_SUCCESS = 'RESET_OPPORTUNITY_IMAGE_SUCCESS';
export const ADD_OPPORTUNITY_DETAILS_SUCCESS = "ADD_OPPORTUNITY_DETAILS_SUCCESS";
export const ADD_OPPORTUNITY_DETAILS_FAILURE = "ADD_OPPORTUNITY_DETAILS_FAILURE";
export const SET_PERK_IMAGE_SUCCESS = 'SET_PERK_IMAGE_SUCCESS';
export const RESET_PERK_IMAGE_SUCCESS = 'RESET_PERK_IMAGE_SUCCESS';
export const ADD_PERK_DETAILS_SUCCESS = "ADD_PERK_DETAILS_SUCCESS";
export const ADD_PERK_DETAILS_FAILURE = "ADD_PERK_DETAILS_FAILURE";
export const GET_SINGLE_PERK_DETAILS_SUCCESS = "GET_SINGLE_PERK_DETAILS_SUCCESS";
export const GET_SINGLE_PERK_DETAILS_FAILURE = "GET_SINGLE_PERK_DETAILS_FAILURE";
export const GET_SINGLE_APPLICANT_DETAILS_SUCCESS = "GET_SINGLE_APPLICANT_DETAILS_SUCCESS";
export const GET_SINGLE_APPLICANT_DETAILS_FAILURE = "GET_SINGLE_APPLICANT_DETAILS_FAILURE";
export const UPDATE_SINGLE_PERK_DETAILS_FAILURE = "UPDATE_SINGLE_PERK_DETAILS_FAILURE";
export const UPDATE_SINGLE_PERK_DETAILS_SUCCESS = "UPDATE_SINGLE_PERK_DETAILS_SUCCESS";
export const GET_SINGLE_OPPORTUNITY_DETAILS_SUCCESS = "GET_SINGLE_OPPORTUNITY_DETAILS_SUCCESS";
export const GET_SINGLE_OPPORTUNITY_DETAILS_FAILURE = "GET_SINGLE_OPPORTUNITY_DETAILS_FAILURE";
export const UPDATE_SINGLE_OPPORTUNITY_DETAILS_FAILURE = "UPDATE_SINGLE_OPPORTUNITY_DETAILS_FAILURE";
export const UPDATE_SINGLE_OPPORTUNITY_DETAILS_SUCCESS = "UPDATE_SINGLE_OPPORTUNITY_DETAILS_SUCCESS";

export function GetAdminDashboard() {
  return (dispatch: AppDispatch) => {
    // perform actions
    dispatch({
      type: GET_ADMIN_DASHBOARD_DATA,
      payload: {
        // add payload here
      },
    });
  };
};


export function GetCreatorList(searchString, page) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse = await getCreatorList(searchString, page);
      const { data, count, keys } = response.data.data;
      if (data.length > 0) {
        dispatch({
          type: GET_CREATOR_LIST,
          payload: { data, keys },
        });
        dispatch({
          type: SET_USERS_COUNT,
          payload: count
        });
      } else {
        dispatch({
          type: GET_CREATOR_LIST_FAILURE
        });
      }
    } catch (e) {
      dispatch({
        type: GET_CREATOR_LIST_FAILURE
      });
    }
  };
};

export function GetBrandList(searchString, page) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse = await getBrandListAPI(searchString, page);
      const { data, count, keys } = response.data.data;
      if (data.length > 0) {
        dispatch({
          type: GET_BRAND_LIST,
          payload: { data:[], keys },
        });
        dispatch({
          type: GET_BRAND_LIST,
          payload: { data, keys },
        });
        dispatch({
          type: SET_BRAND_COUNT,
          payload: count
        });
      }
      else {
        dispatch({
          type: GET_BRAND_LIST_FAILURE,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_BRAND_LIST_FAILURE
      });
    }
  };
};

export function GetReviewsList(searchString, page, userId) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse =
        await getReviewsAPI(searchString, page, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_REVIEW_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_REVIEW_COUNT,
        payload: count
      });
    } catch (e) {
      dispatch({
        type: GET_REVIEW_LIST_FAILURE
      });
    }
  };
};
export function GetOpportunityList(searchString, page, userId) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse =
        await getOpportunityAPI(searchString, page, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_OPPORTUNITY_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_OPPORTUNITY_COUNT,
        payload: count
      });
    } catch (e) {
      dispatch({
        type: GET_OPPORTUNITY_LIST_FAILURE
      });
    }
  };
};

export function GetPerksList(searchString, page, userId) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse =
        await getPerksAPI(searchString, page, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_PERKS_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_PERKS_COUNT,
        payload: count
      });
    } catch (e) {
      dispatch({
        type: GET_PERKS_LIST_FAILURE
      });
    }
  };
};

export function searchCreators(searchString) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await getCreatorList(searchString, 0);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_CREATOR_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_USERS_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}


export function searchBrands(searchString) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await getBrandListAPI(searchString, 0);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_BRAND_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_BRAND_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}


export function exportCreator() {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await exportCreatorAPI();
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
      return response;
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}

export function updateUserStatus(
  status: string, userId: string, type: string,
  searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const response: any = await updateUserStatusAPI(status, userId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      if (type === "creator") {
        dispatch(GetCreatorList(searchString, page - 1));
      }
      else {
        dispatch(GetBrandList(searchString, page - 1));
      }
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}
export function updatePinStatus(
  isPin: string, rowId: string, type: string,
  searchString: string, page: number
) {

  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const adminId = getFromSession(SessionKeys.userId).result;
    
      if (type === "opportunity") {
        const response: any = await updatePinStatusApi(adminId, isPin, rowId);
    
        if (response.data.code == 200) {
          dispatch({
            type: CORE_ACTIONS.ENABLE_MODAL,
            payload: {
              message: response.data.message,
              severity: getAlertType(response.data.code)
            }
          });
        }
    
        dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
        dispatch(GetOpportunityList(searchString, page - 1, adminId));
      } else if (type === "perk") {
        const response: any = await updatePerkPinStatusApi(adminId, isPin, rowId); // Assuming updatePerkStatusApi is the API for perks
    
        if (response.data.code == 200) {
          dispatch({
            type: CORE_ACTIONS.ENABLE_MODAL,
            payload: {
              message: response.data.message,
              severity: getAlertType(response.data.code)
            }
          });
        }
    
        dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
        dispatch(GetPerksList(searchString, page - 1, adminId)); // Assuming GetPerkList is the action for perks
      }
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}
export function updateReviewStatus(
  status: string, userId: string, reviewId: string,
  type: string, searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const adminId = getFromSession(SessionKeys.userId).result;
      let response: any = {};
      if(status === 'DELETE') response = await deleteReviewAPI(adminId, reviewId);
      else response = await updateReviewStatusAPI(status, userId, reviewId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      dispatch(GetReviewsList(searchString, page - 1, adminId));
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}


export function searchReviews(searchString, userId) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse =
        await getReviewsAPI(searchString, 0, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_REVIEW_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_REVIEW_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}
export function searchOpportunity(searchString, userId) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse =
        await getOpportunityAPI(searchString, 0, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_OPPORTUNITY_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_OPPORTUNITY_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}

export function searchPerks(searchString, userId) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse =
        await getPerksAPI(searchString, 0, userId);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_PERKS_LIST,
        payload: { data, keys },
      });
      dispatch({
        type: SET_PERKS_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}

export function assignBrand(
  userId: string, brandId: string, searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    try {
      const response: any = await assignUserAPI(userId, brandId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch(GetBrandList(searchString, page - 1));
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}
export function assignReviewToBrand(
  reviewId: string, brandId: string, searchString: string, page: number
) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    const adminId = getFromSession(SessionKeys.userId).result;
    try {
      const response: any = await assignReviewAPI(reviewId, brandId,adminId);
      if (response.data.code == 200) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code)
          }
        });
      };
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      dispatch(GetReviewsList(searchString, page - 1,adminId));
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    }
  };
}

export function getOpportunityApplicants(userId, oppId, searchString, page) {
  return async (dispatch: AppDispatch) => {
    try {
      const response: AxiosResponse = await getApplicantList(searchString, 
        userId, oppId, page);
      const { data, count, keys } = response.data.data;

      if (data.length > 0) {
        dispatch({
          type: GET_OPPORTUNITY_APPLICANTS,
          payload: { data, keys },
        });
        dispatch({
          type: SET_USERS_COUNT,
          payload: count
        });
      } else {
        dispatch({
          type: GET_OPPORTUNITY_APPLICANTS_FAILURE
        });
      }
    } catch (e) {
      dispatch({
        type: GET_OPPORTUNITY_APPLICANTS_FAILURE
      });
    }
  };
};


export function AddOpportunityImageAction(userId, formData: any) {
  const body = formData;
  return async (dispatch: AppDispatch | any) => {
    try {
      const response = await uploadOpportunityImage(userId, body);
      const img = response.data.data.url;
      dispatch({
        type: SET_OPPORTUNITY_IMAGE_SUCCESS,
        payload: img,
      });
    } catch (error) {
      if(error.message.toLowerCase() === "network error") {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: "Opportunity image size should be less than or equal to 5MB.",
            severity: getAlertType(400),
            alertTitle: "Error",
          },
        });
      } else {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: error.data.message,
            severity: getAlertType(error.data.code),
            alertTitle: error.data.statusText,
          },
        });
      }
    }
  };
}

export function addOpportunityAction(userId: string, payload: any) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await addOpportunityRequest(
        userId,
        payload
      );
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      dispatch({
        type: ADD_OPPORTUNITY_DETAILS_SUCCESS,
        payload: {
          status: response?.data?.status,
        },
      });
      history.push('/manage-opportunities');
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: ADD_OPPORTUNITY_DETAILS_FAILURE });
    }
  };
}

export function AddPerkImageAction(userId, formData: any) {
  const body = formData;
  return async (dispatch: AppDispatch | any) => {
    try {
      const response = await uploadPerkImage(userId, body);
      const img = response.data.data.url;
      dispatch({
        type: SET_PERK_IMAGE_SUCCESS,
        payload: img,
      });
    } catch (error) {
      if(error.message.toLowerCase() === "network error") {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: "Perk image size should be less than or equal to 5MB.",
            severity: getAlertType(400),
            alertTitle: "Error",
          },
        });
      } else {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: error.data.message,
            severity: getAlertType(error.data.code),
            alertTitle: error.data.statusText,
          },
        });
      }
    }
  };
}

export function addPerkAction(userId: string, payload: any) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await addPerkRequest(
        userId,
        payload
      );
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      dispatch({
        type: ADD_PERK_DETAILS_SUCCESS,
        payload: {
          status: response?.data?.status,
        },
      });
      history.push('/manage-perks');
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: ADD_PERK_DETAILS_FAILURE });
    }
  };
}

export function getSinglePerkAction(userId: string, perkId: string) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await getSinglePerkRequest(
        userId,
        perkId
      );
      dispatch({
        type: GET_SINGLE_PERK_DETAILS_SUCCESS,
        payload:  response?.data?.data,
      });
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: GET_SINGLE_PERK_DETAILS_FAILURE });
    }
  };
}
export function getSingleApplicantAction(applicantId: string, opportunityId: string) {
  return async function (dispatch: AppDispatch) {
    const adminId = getFromSession(SessionKeys.userId).result;
    try {
      const response: AxiosResponse = await getSingleApplicant(
        adminId,
        applicantId,
        opportunityId
      );
      dispatch({
        type: GET_SINGLE_APPLICANT_DETAILS_SUCCESS,
        payload:  response?.data?.data,
      });
      dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: GET_SINGLE_APPLICANT_DETAILS_FAILURE });
    }
  };
}

export function updatePerkAction(userId: string, perkId:string, body: any) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await updatePerkRequest(
        userId,
        perkId,
        body
      );
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      dispatch({
        type: UPDATE_SINGLE_PERK_DETAILS_SUCCESS
      });
      history.push('/manage-perks');
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: UPDATE_SINGLE_PERK_DETAILS_FAILURE });
    }
  };
}

export function getSingleOpportunityAction(userId: string, oppId: string) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await getSingleOpportunityRequest(
        userId,
        oppId
      );
      dispatch({
        type: GET_SINGLE_OPPORTUNITY_DETAILS_SUCCESS,
        payload:  response?.data?.data,
      });
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: GET_SINGLE_OPPORTUNITY_DETAILS_FAILURE });
    }
  };
}

export function updateOpportunityAction(userId: string, 
  opportunityId:string, body: any) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await updateOpportunityRequest(
        userId,
        opportunityId,
        body
      );
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      dispatch({
        type: UPDATE_SINGLE_OPPORTUNITY_DETAILS_SUCCESS
      });
      history.push('/manage-opportunities');
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: UPDATE_SINGLE_OPPORTUNITY_DETAILS_FAILURE });
    }
  };
}

export function searchApplicants(userId, oppId, searchString ) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await getApplicantList(searchString, 
        userId, oppId, 1);
      const { data, count, keys } = response.data.data;
      dispatch({
        type: GET_OPPORTUNITY_APPLICANTS,
        payload: { data, keys },
      });
      dispatch({
        type: SET_USERS_COUNT,
        payload: count
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}

export function exportOpportunityApplicants(userId: string, id: string) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.START_INLINE_LOADER });
    try {
      const response: AxiosResponse = await exportOpportunityApplicantsAPI(
        userId,id);
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
      return response;
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.STOP_INLINE_LOADER });
    }
  };
}
import React from 'react';

const Twitter = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
    <path
      d="M23.501 0C10.5217 0 0 10.7524 0 24.0118C0 37.2732 10.5217 48.0217 23.501 48.0217C36.4802 48.0217 47 37.2732 47 24.0118C47 10.7524 36.4792 0 23.501 0ZM35.3388 19.1705C35.3512 19.4298 35.3559 19.6902 35.3559 19.9535C35.3559 27.9503 29.3989 37.1698 18.5059 37.1698C15.1618 37.1698 12.0478 36.1684 9.4271 34.4522C9.89092 34.5078 10.3614 34.5361 10.8395 34.5361C13.6148 34.5361 16.1687 33.5688 18.1947 31.9462C15.6037 31.8965 13.4173 30.1462 12.6633 27.7426C13.0241 27.8147 13.3953 27.8508 13.777 27.8508C14.3172 27.8508 14.8402 27.7787 15.3374 27.6392C12.6271 27.0834 10.5857 24.6359 10.5857 21.7067C10.5857 21.6804 10.5857 21.655 10.5866 21.6287C11.3845 22.0821 12.2978 22.3542 13.2693 22.3864C11.6794 21.2991 10.6344 19.4494 10.6344 17.349C10.6344 16.2403 10.9264 15.1999 11.436 14.3067C14.3573 17.9672 18.7216 20.3767 23.6441 20.6282C23.5429 20.1865 23.4905 19.7234 23.4905 19.2495C23.4905 15.9088 26.1416 13.199 29.4132 13.199C31.1167 13.199 32.6551 13.9342 33.7354 15.1092C35.0849 14.8391 36.3523 14.334 37.4965 13.6407C37.0528 15.0546 36.1146 16.2403 34.8912 16.9882C36.0908 16.8419 37.2322 16.5162 38.2934 16.0355C37.5013 17.2515 36.4964 18.3173 35.3388 19.1705Z"
      fill={iconColor}
    />
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="48" height="48.5783" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

Twitter.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48',
  height: '48'
};

export default Twitter;
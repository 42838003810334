import React from 'react';
import { Typography, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface INotFound {
  to?: string;
  navLinkName?: string;
}

function NotFound(notFoundProps: INotFound) {
  const { to, navLinkName } = notFoundProps;
  const toSendLink = to?.length ? to : '/dashboard';
  const navName = navLinkName?.length ? navLinkName : 'Dashboard';
  return (
    <Stack sx={{ height: 'calc(100vh - (4rem + 41px))'}} justifyContent='center' alignItems='center'>
      <Typography color="primary.main" variant="h2">Page Not Found</Typography>
      <Typography color="secondary.main" variant="h6" sx={{m: '4rem'}}>
        We&#39;re sorry the page you requested cannot be found
      </Typography>
      <Button
        variant="contained"
        sx={{
            p: 0,
            opacity: "1",
            height:'40px',
            textTransform: 'uppercase',
            boxShadow: "0px 3px 10px 3px rgba(66, 17, 207, 0.3)",
          }}
        >
          <Link
            to={toSendLink}
            style={{
              padding: '8px 24px',
              textDecoration: 'none', color: 'white'
              }}>
            GO TO {navName}
          </Link>
      </Button>
    </Stack>
  );
}

export default NotFound;
import { AlertColor } from '@mui/material';

export const getAlertType = (code: number): AlertColor => {
  switch (code) {
    case 200:
    case 201:
    case 204:
      return 'success';

    case 404:
    case 400:
    case 401:
    case 403:
    case 500:
    case 409:
      return 'error';
    default:
      return 'success';
  }
};

export const getDateHumanReadable = (date: string) => {
  const options:any = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);
  return formattedDate.replace(/,/, '');
};

export const formatDate = (inputDate: string) => {
  // Create a Date object from the input string
  const date = new Date(inputDate);

  // Extract day, month, and year components
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  // Format the output string
  return `${month}-${day}-${year}`;
};
/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';
import { AppDispatch } from '../../../../store/store';
import { getRecentReviews } from './api';

export const GET_CREATOR_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';

export enum HOMEPAGE_ACTIONS {
  GET_HOMEPAGE_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST',
  GET_HOMEPAGE_REVIEWS_SUCCESS = 'GET_HOMEPAGE_REVIEWS_SUCCESS',
  GET_HOMEPAGE_REVIEWS_FAILURE = 'GET_HOMEPAGE_REVIEWS_FAILURE',
}

export function GetCreatorDashboard () {
  return (dispatch: AppDispatch) => {
    // perform actions
    dispatch({
      type: GET_CREATOR_DASHBOARD_DATA,
      payload: {
        // add payload here
      },
    });
  };
};


export function getReviewsForHomePage() {
  return async function (dispatch) {
    dispatch({ type: HOMEPAGE_ACTIONS.GET_HOMEPAGE_REVIEWS_REQUEST });
    try {
      const response: AxiosResponse = await getRecentReviews();
      if (response.data) {
        dispatch({
          type: HOMEPAGE_ACTIONS.GET_HOMEPAGE_REVIEWS_SUCCESS,
          payload: response.data.data.reviews
        });
      } else {
        dispatch({ type: HOMEPAGE_ACTIONS.GET_HOMEPAGE_REVIEWS_FAILURE });
      }
    } catch (error) {
      dispatch({ type: HOMEPAGE_ACTIONS.GET_HOMEPAGE_REVIEWS_FAILURE });
    };
  };
};
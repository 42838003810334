import { ISelectedPortfolio, ReducerActionProps } from '../../../../shared/utilities/interfaces';
import { CREATOR_PROFILE_ACTIONS, CREATOR_PORTFOLIO_ACTIONS } from '../store/actions';

import { ISelectedUser, ICountriesData, ICurrentUser } from '../../../../shared/utilities/interfaces';

const initialState: {
  selectedUser: ISelectedUser;
  updatedProfilePic: string;
  otherCreatorData: ISelectedUser;
  countriesData: ICountriesData[];
  portfolioUser: ICurrentUser;
  addPortfolioImg: string;
  selectedPortfolio: ISelectedPortfolio;
  shareUserStatus:boolean
} = {
  selectedUser: {
    aboutMeDescription: "",
    ageRange: "",
    city: "",
    contentType: {
      primary: "",
      secondaryType1: "",
      secondaryType2: ""
    },
    country: "",
    diversIdentity: "",
    firstName: "",
    gender: "",
    isAgeRangePrivate: false,
    isLastNamePrivate: false,
    lastName: "",
    profileImage: "",
    skills: [],
    portfolio: {
      featured: [],
      all: []
    },
    followingBrands: [],
    userId: "",
    socialMedia: [],
  },
  otherCreatorData: {
    aboutMeDescription: "",
    ageRange: "",
    city: "",
    contentType: {
      primary: "",
      secondaryType1: "",
      secondaryType2: ""
    },
    country: "",
    diversIdentity: "",
    firstName: "",
    gender: "",
    isAgeRangePrivate: false,
    isLastNamePrivate: false,
    lastName: "",
    profileImage: "",
    skills: [],
    portfolio: {
      featured: [],
      all: []
    },
    followingBrands: [],
    userId: "",
    socialMedia: [],
  },
  updatedProfilePic: "",
  countriesData: [],
  portfolioUser: {
   title: "",
    type: "",
    url: "",
    description: "",
    isFeatured: false,
    portfolioImageUrl: "",
  },
  addPortfolioImg:"",
  selectedPortfolio: {
    title: "",
    type: "",
    url: "",
    description: "",
    isFeatured: "",
    portfolioUrl: "",
  },
  shareUserStatus:false

};

const creatorProfileReducer = (
  state = initialState,
  action: ReducerActionProps
  ) => {
    switch (action.type) {
      case CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_SUCCESS:
        return {
          ...state,
          ...action.payload,
        };
      case CREATOR_PROFILE_ACTIONS.GET_COUNTRIES_DATA_SUCCESS:
        return {
          ...state,
          countriesData: action.payload.countries
        };
      case CREATOR_PROFILE_ACTIONS.SET_PROFILE_PIC_SUCCESS:
        return {
          ...state,
          updatedProfilePic: action.payload.profileImage
      };
      case CREATOR_PROFILE_ACTIONS.RESET_PROFILE_PIC_SUCCESS:
        return {
          ...state,
          updatedProfilePic: ""
      };
      case CREATOR_PORTFOLIO_ACTIONS.RESET_PORTFOLIO_PIC_SUCCESS:
        return {
          ...state,
          addPortfolioImg: ""
      };
      case CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_REQUEST:
        return {
          ...state,
        };
      case CREATOR_PROFILE_ACTIONS.GET_PROFILE_DATA_FAILURE:
        return {
          ...state,
          };
      case CREATOR_PROFILE_ACTIONS.SHARE_USER_STATUS_SUCCESS:
        return {
          ...state,
          shareUserStatus:action.payload.shareUserStatus
          };
      case CREATOR_PORTFOLIO_ACTIONS.ADD_PORTFOLIO_REQUEST: 
        return {
          ...state
        };
      case CREATOR_PORTFOLIO_ACTIONS.ADD_PORTFOLIO_SUCCESS: 
        return {
          ...state,
          ...action.payload
        };
      case CREATOR_PORTFOLIO_ACTIONS.ADD_PORTFOLIO_FAILURE: 
        return {
          ...state
        };
      
      case CREATOR_PORTFOLIO_ACTIONS.SET_PORTFOLIO_IMAGE_SUCCESS: 
        return {
          ...state,
          addPortfolioImg:action.payload.addPortfolioImage
        };
      case CREATOR_PROFILE_ACTIONS.GET_OTHER_PROFILE_DATA_SUCCESS:
        return {
          ...state,
          ...action.payload
        };

      case CREATOR_PORTFOLIO_ACTIONS.SET_PORTFOLIO_DATA_SUCCESS:
        return {
          ...state,
          ...action.payload
        };
      default:
        return {
          ...state,
        };
    }
};

export default creatorProfileReducer;
import { AxiosResponse } from 'axios';
import { AppDispatch, history } from 'store/store';
import { applyOpportunityRequest, getCreatorOpportunityRequest } from './api';
import { CORE_ACTIONS } from 'core/store/app/actions';
import { getAlertType } from 'shared/helpers';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IApplyOpportunityData } from 'shared/utilities/interfaces';

export const GET_CREATOR_OPPORTUNITY_DETAILS_SUCCESS = 'GET_CREATOR_OPPORTUNITY_DETAILS_SUCCESS';
export const GET_CREATOR_OPPORTUNITY_DETAILS_FAILURE = 'GET_CREATOR_OPPORTUNITY_DETAILS_FAILURE';
export const APPLY_CREATOR_OPPORTUNITY_SUCCESS = 'APPLY_CREATOR_OPPORTUNITY_SUCCESS';
export const APPLY_CREATOR_OPPORTUNITY_FAILURE = 'APPLY_CREATOR_OPPORTUNITY_FAILURE';

export function getCreatorOpportunityAction(userId: string, id: string
): ThunkAction<Promise<void>, {}, {}, AnyAction>  {
    return async function (dispatch: AppDispatch): Promise<void> {
      try {
        const response: AxiosResponse = await getCreatorOpportunityRequest(
          userId,
          id,
        );
        dispatch({
          type: GET_CREATOR_OPPORTUNITY_DETAILS_SUCCESS,
          payload:  response?.data?.data,
        });
      } catch (e) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: e.response?.data?.message,
            severity: getAlertType(e.response?.data?.code),
            alertTitle: e.response?.data?.error,
          },
        });
        dispatch({ type: GET_CREATOR_OPPORTUNITY_DETAILS_FAILURE });
        history.push('/creator-opportunities');
      }
    };
  }


export function applyOpportunityAction(userId: string, id: string,
  data: IApplyOpportunityData): ThunkAction<Promise<void>, {}, {}, AnyAction>  {
    return async function (dispatch: AppDispatch): Promise<void> {
      try {
        const response: AxiosResponse = await applyOpportunityRequest(
          userId,
          id,
          data,
        );
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: response.data.message,
            severity: getAlertType(response.data.code),
            alertTitle: response.data.statusText,
          },
        });
        dispatch({
          type: APPLY_CREATOR_OPPORTUNITY_SUCCESS
        });
        history.push('/creator-opportunities');
      } catch (e) {
        dispatch({
          type: CORE_ACTIONS.ENABLE_MODAL,
          payload: {
            message: e.response?.data?.message,
            severity: getAlertType(e.response?.data?.code),
            alertTitle: e.response?.data?.error,
          },
        });
        dispatch({ type: APPLY_CREATOR_OPPORTUNITY_FAILURE });
      }
    };
  }
import React from 'react';

const Instagram = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8043 28.4873C26.1751 28.4873 28.097 26.5655 28.097 24.1947C28.097 21.824 26.1751 19.9021 23.8043 19.9021C21.4336 19.9021 19.5117 21.824 19.5117 24.1947C19.5117 26.5655 21.4336 28.4873 23.8043 28.4873Z"
      fill={iconColor}
    />
    <path
      d="M29.1698 13.6582H18.4382C16.8773 13.6582 15.5115 14.146 14.6334 15.024C13.7554 15.9021 13.2676 17.2679 13.2676 18.8289V29.5604C13.2676 31.1214 13.7554 32.4872 14.731 33.4628C15.7066 34.3408 16.9748 34.8286 18.5358 34.8286H29.1698C30.7308 34.8286 32.0966 34.3408 32.9746 33.4628C33.9502 32.5848 34.438 31.2189 34.438 29.658V18.9264C34.438 17.3655 33.9502 16.0972 33.0722 15.1216C32.0966 14.146 30.8283 13.6582 29.1698 13.6582ZM23.804 30.8287C20.0968 30.8287 17.17 27.8043 17.17 24.1946C17.17 20.4874 20.1943 17.5606 23.804 17.5606C27.4137 17.5606 30.5356 20.4874 30.5356 24.1946C30.5356 27.9019 27.5113 30.8287 23.804 30.8287ZM30.7308 18.8289C29.8527 18.8289 29.1698 18.1459 29.1698 17.2679C29.1698 16.3899 29.8527 15.707 30.7308 15.707C31.6088 15.707 32.2917 16.3899 32.2917 17.2679C32.2917 18.1459 31.6088 18.8289 30.7308 18.8289Z"
      fill={iconColor}
    />
    <path
      d="M23.9997 0C10.7316 0 0 10.7316 0 23.9997C0 37.2678 10.7316 47.9993 23.9997 47.9993C37.2678 47.9993 47.9993 37.2678 47.9993 23.9997C48.0969 10.7316 37.2678 0 23.9997 0ZM36.78 29.6581C36.78 31.902 35.9995 33.8532 34.6337 35.219C33.2678 36.5849 31.3166 37.2678 29.1703 37.2678H18.5363C16.39 37.2678 14.4388 36.5849 13.073 35.219C11.6096 33.8532 10.9267 31.902 10.9267 29.6581V18.9266C10.9267 14.4388 13.951 11.3169 18.5363 11.3169H29.2679C31.5118 11.3169 33.3654 12.0974 34.7312 13.4632C36.0971 14.8291 36.78 16.6827 36.78 18.9266V29.6581Z"
      fill={iconColor}
    />
  </svg>
);

Instagram.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48',
  height: '48'
};

export default Instagram;
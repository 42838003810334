import React from 'react';

const UgcPhoto = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">    
    <g>
      <path 
        clipRule="evenodd"
        fillRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.0796 5.74067H5.74058V11.8217L7.52669 9.02698L8.27953 10.3991L9.83016 7.60787L11.9601 11.5526L12.6472 10.4103L14.2592 12.9405V7.46167C13.9998 7.80721 13.5917 8.017 13.148 8.017C12.381 8.017 11.7591 7.39514 11.7591 6.62811C11.7591 6.2989 11.8743 5.98751 12.0796 5.74067ZM15 15V5H5V15H15Z"
        fill={iconColor}
      />
    </g>
  </svg>  
);

UgcPhoto.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48',
  height: '48'
};

export default UgcPhoto;
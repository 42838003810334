/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';
import { SessionKeys } from '../../../../core/constants';
import { CORE_ACTIONS } from '../../../../core/store/app/actions';
import { getAlertType } from '../../../../shared/helpers';
import { getFromSession } from '../../../../shared/helpers/auth.helper';
import { ActionDispatchType } from '../../../../shared/utilities/interfaces';
import { AppDispatch, history } from '../../../../store/store';
import {
  getAllBrands,
  getBrandData,
  getReviewsApi,
  getBrandFollowingUsers,
  getFollowedBrands,
  followBrand,
  addReview,
  suggestBrand
} from './api';

export enum CREATOR_ACTIONS {
  GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST',
  GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS',
  GET_BRANDS_FAILURE = 'GET_BRANDS_FAILURE',
  GET_BRANDS_DATA = 'GET_BRANDS_DATA',
  SEARCH_BRANDS_REQUEST = 'SEARCH_BRANDS_REQUEST',
  SEARCH_BRANDS_SUCCESS = 'SEARCH_BRANDS_SUCCESS',
  SEARCH_BRANDS_FAILURE = 'SEARCH_BRANDS_FAILURE',
  GET_BRAND_DATA_REQUEST = 'GET_BRANDS_DATA_REQUEST',
  GET_BRAND_DATA_SUCCESS = 'GET_BRANDS_DATA_SUCCESS',
  GET_BRAND_DATA_FAILURE = 'GET_BRANDS_DATA_FAILURE',
  GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST',
  GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS',
  GET_REVIEWS_FAILURE = 'GET_REVIEWS_FAILURE',
  GET_BRAND_FOLLOWERS_REQUEST = 'GET_BRAND_FOLLOWERS_REQUEST',
  GET_BRAND_FOLLOWERS_SUCCESS = 'GET_BRAND_FOLLOWERS_SUCCESS',
  GET_BRAND_FOLLOWERS_FAILURE = 'GET_BRAND_FOLLOWERS_FAILURE',
  GET_FOLLOWED_BRANDS_SUCCESS = 'GET_FOLLOWED_BRANDS_SUCCESS',
  GET_FOLLOWED_BRANDS_FAILURE = 'GET_FOLLOWED_BRANDS_FAILURE',
  GET_FOLLOWED_BRANDS_REQUEST = 'GET_FOLLOWED_BRANDS_REQUEST',
  FOLLOW_BRAND_REQUEST = 'FOLLOW_BRAND_REQUEST',
  FOLLOW_BRAND_SUCCESS = 'FOLLOW_BRAND_SUCCESS',
  FOLLOW_BRAND_FAILURE = 'FOLLOW_BRAND_FAILURE',
  SET_PROFILE_PIC_SUCCESS = 'SET_PROFILE_PIC_SUCCESS',
  SET_BRAND_PROFILE_DATA_FAILURE = 'SET_BRAND_PROFILE_DATA_FAILURE',
  START_INLINE_LOADER = 'START_INLINE_LOADER',
  STOP_INLINE_LOADER = 'STOP_INLINE_LOADER',
  RESET_ADD_BRAND_PROFILE_PIC = 'RESET_ADD_BRAND_PROFILE_PIC'
}

export function Getbrands() {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: CREATOR_ACTIONS.GET_BRANDS_DATA,
      payload: {
        // add payload here
      },
    });
  };
};

export function searchBrands(body) {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.SEARCH_BRANDS_REQUEST });
    try {
      const response: AxiosResponse = await getAllBrands(body);
      dispatch({
        type: CREATOR_ACTIONS.SEARCH_BRANDS_SUCCESS,
        payload: {
          searchList: response.data.data
        }
      });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.SEARCH_BRANDS_FAILURE });
      return {
        success: false
      };
    }
  };
}

export function resetSearch() {
  return function (dispatch: AppDispatch) {
    dispatch({
      type: CREATOR_ACTIONS.SEARCH_BRANDS_SUCCESS,
      payload: {
        searchList: []
      }
    });
  };
}

export function loadAllBrands() {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.GET_BRAND_DATA_REQUEST });
    try {
      const response: AxiosResponse = await getAllBrands('');
      dispatch({
        type: CREATOR_ACTIONS.GET_BRANDS_SUCCESS,
        payload: {
          brandsList: response.data.data,
        }
      });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.GET_BRANDS_FAILURE });
      return {
        success: false
      };
    }
  };
}

export function loadBrandDataAction(brandId, singleUserId = "") {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await getBrandData(brandId , singleUserId);
      dispatch({
        type: CREATOR_ACTIONS.GET_BRAND_DATA_SUCCESS,
        payload: {
          selectedBrand: { ...response.data.data }
        }
      });
    } catch (e) {
      dispatch({ type: CREATOR_ACTIONS.GET_BRAND_DATA_FAILURE });
    }
  };
}

export function followBrandAction(body, brandId) {
  return async function (dispatch: AppDispatch | ActionDispatchType) {
    dispatch({ type: CREATOR_ACTIONS.FOLLOW_BRAND_REQUEST });
    try {
      body.user_id = getFromSession(SessionKeys.userId).result;
      await followBrand(body);
      // enable after api updated with isFollowing
      await dispatch(loadBrandDataAction(brandId, ""));
      dispatch(getBrandFollowersAction(brandId));
    } catch (e) {
      dispatch({ type: CREATOR_ACTIONS.FOLLOW_BRAND_FAILURE });
    }
  };
}

export function getReviewsAction(payload: {
  brandId: string;
  limit: string;
  offset: string;
  body: any;
}) {
  return async function (dispatch) {
    dispatch({ type: CREATOR_ACTIONS.GET_REVIEWS_REQUEST });
    try {
      const { brandId, limit, offset, body } = payload;
      const response: AxiosResponse = await getReviewsApi(
        brandId, limit, offset, body
      );
      if(response.data) {
        dispatch({
          type: CREATOR_ACTIONS.GET_REVIEWS_SUCCESS,
          payload: {
            reviewsList: response.data.data.reviews
          }
        });
      } else {
        dispatch({ type: CREATOR_ACTIONS.GET_REVIEWS_FAILURE });  
      }
    } catch (e) {
      dispatch({ type: CREATOR_ACTIONS.GET_REVIEWS_FAILURE });
    }
  };
}

export function getBrandFollowersAction(brandId: string) {
  return async function (dispatch) {
    try {
      const response: AxiosResponse = await getBrandFollowingUsers(brandId);
      dispatch({
        type: CREATOR_ACTIONS.GET_BRAND_FOLLOWERS_SUCCESS,
        payload: {
          brandFollowers: response.data.data
        }
      });
    } catch (e) {
      dispatch({ type: CREATOR_ACTIONS.GET_BRAND_FOLLOWERS_FAILURE });
    }
  };
}

export function loadFollowedBrands() {
  return async function (dispatch: AppDispatch) {
    dispatch({ type: CREATOR_ACTIONS.GET_FOLLOWED_BRANDS_REQUEST });
    try {
      const response: AxiosResponse = await getFollowedBrands();
      dispatch({
        type: CREATOR_ACTIONS.GET_FOLLOWED_BRANDS_SUCCESS,
        payload: {
          followedList: response.data.data
        }
      });
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      dispatch({ type: CREATOR_ACTIONS.GET_FOLLOWED_BRANDS_FAILURE });
      return {
        success: false
      };
    }
  };
}

export function addReviewAction(body) {
  return async function (dispatch: AppDispatch) {
    try {
      const response: AxiosResponse = await addReview(body);
      if(body.brandId === 'other'){
        history.push(`/dashboard`);
      } else {
        history.push(`/brands/brand-profile/${body.brandId}`);
      }
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.status
        }
      });
      return {
        success: true
      };
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
    }
  };
}

export function suggestBrandAction(body) {
  return async function (dispatch: AppDispatch) {
    try {
      const user_id = getFromSession(SessionKeys.userId).result;
      const response: AxiosResponse = await suggestBrand(body, user_id);
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.status
        }
      });
      return {
        success: true
      };
    }
    catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response.data.message,
          severity: getAlertType(e.response.data.code),
          alertTitle: e.response.data.error
        }
      });
      return {
        success: false
      };
    }
  };
}

export function startInlineLoaderAction() {
  return function (dispatch: AppDispatch) {
    dispatch({type: CREATOR_ACTIONS.START_INLINE_LOADER});
  };
}

export function stopInlineLoaderAction() {
  return function (dispatch: AppDispatch) {
    dispatch({type: CREATOR_ACTIONS.STOP_INLINE_LOADER});
  };
}

export function ResetBrandProfilePicAction() {
  return async (dispatch: AppDispatch | any) => {
    dispatch({
      type: CREATOR_ACTIONS.RESET_ADD_BRAND_PROFILE_PIC
    });
  };
}
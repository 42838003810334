import { AppDispatch } from '../../../../store/store';

export const GET_ADMIN_BRANDLIST_DATA = 'GET_BRANDLIST_DATA';

export function GetAdminBrandList () {
  return (dispatch: AppDispatch) => {
    // perform actions
    dispatch({
      type: GET_ADMIN_BRANDLIST_DATA,
      payload: {
        // add payload here
      },
    });
  };
};

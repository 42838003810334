import { ReducerActionProps } from '../../../../shared/utilities/interfaces';
import {
  GET_CREATOR_DASHBOARD_DATA, HOMEPAGE_ACTIONS
} from './actions';

const initialState:{
  reviewsListHomepage:any
} = {
  reviewsListHomepage:[]
  // add initial state variables
};

const creatorDashboardReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case GET_CREATOR_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload
      };
    case HOMEPAGE_ACTIONS.GET_HOMEPAGE_REVIEWS_SUCCESS:
        return {
          ...state,
          reviewsListHomepage: [...action.payload]
        };
    case HOMEPAGE_ACTIONS.GET_HOMEPAGE_REVIEWS_FAILURE:
        return{
          ...state,
          reviewsListHomepage: []
        }; 
    default:
      return {
        ...state
      };
  }
};

export default creatorDashboardReducer;

import { AxiosResponse } from 'axios';
import { AppDispatch, history } from 'store/store';
import {
  getCreatorPerkClaimRequest,
  getCreatorPerkRequest,
} from './api';
import { CORE_ACTIONS } from 'core/store/app/actions';
import { getAlertType } from 'shared/helpers';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

export const GET_CREATOR_PERK_DETAILS_SUCCESS =
  'GET_CREATOR_PERK_DETAILS_SUCCESS';
export const GET_CREATOR_PERK_DETAILS_FAILURE =
  'GET_CREATOR_PERK_DETAILS_FAILURE';
export const CLAIM_CREATOR_PERK_SUCCESS = 'CLAIM_CREATOR_PERK_SUCCESS';
export const CLAIM_CREATOR_PERK_FAILURE = 'CLAIM_CREATOR_PERK_FAILURE';

export function getCreatorPerkAction(
  userId: string,
  id: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async function (dispatch: AppDispatch): Promise<void> {
    try {
      const response: AxiosResponse = await getCreatorPerkRequest(userId, id);
      dispatch({
        type: GET_CREATOR_PERK_DETAILS_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: GET_CREATOR_PERK_DETAILS_FAILURE });
      history.push('/creator-perks');
    }
  };
}

export function getCreatorPerkClaimAction(
  userId: string,
  id: string
): ThunkAction<Promise<void>, {}, {}, AnyAction> {
  return async function (dispatch: AppDispatch): Promise<void> {
    try {
      const response: AxiosResponse = await getCreatorPerkClaimRequest(
        userId,
        id
      );
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: response.data.message,
          severity: getAlertType(response.data.code),
          alertTitle: response.data.statusText,
        },
      });
      dispatch({
        type: CLAIM_CREATOR_PERK_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (e) {
      dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: e.response?.data?.message,
          severity: getAlertType(e.response?.data?.code),
          alertTitle: e.response?.data?.error,
        },
      });
      dispatch({ type: CLAIM_CREATOR_PERK_FAILURE });
      history.push('/creator-perks');
    }
  };
}

import React from 'react';

const ReviewLock = ({ size, iconColor }) => (
  <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.0003 21.3334H45.3337V16.0001C45.3337 8.64008 39.3603 2.66675 32.0003 2.66675C24.6403 2.66675 18.667 8.64008 18.667 16.0001V21.3334H16.0003C13.067 21.3334 10.667 23.7334 10.667 26.6667V53.3334C10.667 56.2668 13.067 58.6667 16.0003 58.6667H48.0003C50.9337 58.6667 53.3337 56.2668 53.3337 53.3334V26.6667C53.3337 23.7334 50.9337 21.3334 48.0003 21.3334ZM32.0003 45.3334C29.067 45.3334 26.667 42.9334 26.667 40.0001C26.667 37.0667 29.067 34.6667 32.0003 34.6667C34.9337 34.6667 37.3337 37.0667 37.3337 40.0001C37.3337 42.9334 34.9337 45.3334 32.0003 45.3334ZM40.267 21.3334H23.7337V16.0001C23.7337 11.4401 27.4403 7.73342 32.0003 7.73342C36.5603 7.73342 40.267 11.4401 40.267 16.0001V21.3334Z" fill={iconColor} />
  </svg>
);

ReviewLock.defaultProps = {
  iconColor: '#4211CF',
  size: '64'
};

export default ReviewLock;
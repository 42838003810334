import React from 'react';

const Youtube = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.4238 26.0205H16.1616V34.6527H17.8408V26.0205H19.5766V24.5508H14.4238V26.0205Z" fill={iconColor} />
    <path
      d="M24.7354 18.8472C24.9635 18.8472 25.1448 18.7868 25.2783 18.663C25.4118 18.5354 25.4801 18.3619 25.4801 18.1436V13.6701C25.4801 13.4907 25.4118 13.3445 25.2744 13.2364C25.137 13.1243 24.9586 13.0687 24.7354 13.0687C24.5317 13.0687 24.3661 13.1243 24.2364 13.2364C24.1088 13.3445 24.0464 13.4917 24.0464 13.6701V18.1436C24.0464 18.3677 24.1058 18.5412 24.2257 18.663C24.3436 18.7868 24.5152 18.8472 24.7354 18.8472Z" fill={iconColor} />
    <path
      d="M28.4377 27.1092C28.2058 27.1092 27.9767 27.1686 27.7506 27.2865C27.5274 27.4045 27.313 27.5799 27.1132 27.806V24.5508H25.6074V34.6527H27.1132V34.0816C27.3072 34.3116 27.5206 34.4792 27.7487 34.5884C27.9748 34.6966 28.236 34.7502 28.5293 34.7502C28.9689 34.7502 29.31 34.6108 29.54 34.3291C29.7739 34.0455 29.8918 33.642 29.8918 33.1148V28.9785C29.8918 28.3674 29.7681 27.9025 29.5166 27.5867C29.2691 27.269 28.9094 27.1092 28.4377 27.1092ZM28.3558 32.9091C28.3558 33.1489 28.312 33.3185 28.2262 33.4227C28.1405 33.529 28.0069 33.5806 27.8247 33.5806C27.699 33.5806 27.581 33.5524 27.465 33.5007C27.351 33.4491 27.2311 33.3584 27.1132 33.2376V28.5945C27.2146 28.4902 27.3169 28.4132 27.4192 28.3664C27.5216 28.3148 27.6288 28.2923 27.735 28.2923C27.9348 28.2923 28.0908 28.3567 28.1999 28.4843C28.3042 28.6159 28.3558 28.806 28.3558 29.0594V32.9091Z" fill={iconColor} />
    <path
      d="M22.9955 32.8633C22.8561 33.0232 22.7021 33.1548 22.5345 33.261C22.3668 33.3653 22.2265 33.4169 22.1193 33.4169C21.978 33.4169 21.8776 33.3789 21.8133 33.299C21.7528 33.221 21.7197 33.0934 21.7197 32.9179V27.1998H20.2314V33.4325C20.2314 33.8779 20.3192 34.2054 20.4926 34.4315C20.67 34.6527 20.9263 34.7629 21.2714 34.7629C21.5511 34.7629 21.8405 34.6869 22.1378 34.527C22.4351 34.3652 22.7206 34.1372 22.9964 33.836V34.6527H24.4866V27.2008H22.9964V32.8633H22.9955Z" fill={iconColor} />
    <path
      d="M24.6709 0C11.4161 0 0.670898 10.7452 0.670898 24C0.670898 37.2568 11.4161 48 24.6709 48C37.9257 48 48.6709 37.2568 48.6709 24C48.6709 10.7452 37.9247 0 24.6709 0ZM28.5479 11.8533H30.2233V18.1484C30.2233 18.3443 30.2613 18.4837 30.3315 18.5694C30.3997 18.6571 30.5147 18.7029 30.6726 18.7029C30.7944 18.7029 30.9504 18.6435 31.1404 18.5295C31.3266 18.4115 31.5001 18.2653 31.656 18.0841V11.8533H33.3353V20.0635H31.656V19.1571C31.35 19.4904 31.0274 19.7468 30.6911 19.9202C30.3578 20.0937 30.0342 20.1834 29.7185 20.1834C29.3306 20.1834 29.0391 20.0596 28.8413 19.814C28.6473 19.5723 28.5479 19.2068 28.5479 18.7137V11.8533ZM22.3201 13.7304C22.3201 13.0969 22.5443 12.5901 22.9955 12.2091C23.4487 11.8358 24.0559 11.6457 24.8171 11.6457C25.512 11.6457 26.0792 11.8455 26.5246 12.2412C26.9661 12.6369 27.1874 13.1515 27.1874 13.7792V18.0188C27.1874 18.7234 26.97 19.2731 26.5383 19.6707C26.1007 20.0684 25.5032 20.2682 24.7411 20.2682C24.0082 20.2682 23.4205 20.0625 22.9799 19.6532C22.5404 19.238 22.3191 18.6825 22.3191 17.9856V13.7304H22.3201ZM17.7511 8.93531L18.9772 13.3815H19.097L20.2637 8.93531H22.1827L19.9859 15.4477V20.0645H18.0971V15.6534L15.8486 8.93531H17.7511ZM39.718 32.3583C39.718 35.328 37.3098 37.7382 34.3401 37.7382H15.6508C12.6792 37.7382 10.2709 35.328 10.2709 32.3583V28.033C10.2709 25.0614 12.6792 22.6531 15.6508 22.6531H34.3401C37.3098 22.6531 39.718 25.0614 39.718 28.033V32.3583Z" fill={iconColor} />
    <path
      d="M32.9354 27.0155C32.2668 27.0155 31.7269 27.2172 31.3039 27.6266C30.8829 28.032 30.6704 28.5612 30.6704 29.2025V32.5533C30.6704 33.2726 30.8644 33.8359 31.2474 34.2433C31.6304 34.6526 32.1557 34.8583 32.8233 34.8583C33.566 34.8583 34.1235 34.6663 34.4948 34.2813C34.872 33.8934 35.0562 33.3184 35.0562 32.5533V32.1703H33.5241V32.5094C33.5241 32.949 33.4724 33.2326 33.376 33.3603C33.2795 33.4879 33.106 33.5523 32.8584 33.5523C32.6206 33.5523 32.453 33.4782 32.3536 33.3281C32.2561 33.1741 32.2093 32.9032 32.2093 32.5094V31.107H35.0572V29.2035C35.0572 28.4989 34.8759 27.9589 34.5094 27.5798C34.142 27.2036 33.6167 27.0155 32.9354 27.0155ZM33.5241 29.9472H32.2084V29.1938C32.2084 28.8819 32.2561 28.6548 32.3604 28.5252C32.4647 28.3858 32.6343 28.3195 32.8721 28.3195C33.0962 28.3195 33.2658 28.3858 33.3672 28.5252C33.4695 28.6548 33.5231 28.8809 33.5231 29.1938V29.9472H33.5241Z" fill={iconColor} />
  </svg>

);

Youtube.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48',
  height: '48'
};

export default Youtube;

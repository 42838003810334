import { createTheme } from '@mui/material';
import { IState } from '../../shared/utilities/interfaces';
import store from '../../store/store';
import Helvetica from '../../assets/font/Helvetica.ttf';
import HelveticaBold from '../../assets/font/Helvetica-Bold.ttf';
import HelveticaLight from '../../assets/font/Helvetica-Light.ttf';
import HelveticaItalic from '../../assets/font/Helvetica-Oblique.ttf';
import HelveticaBoldItalic from '../../assets/font/Helvetica-BoldOblique.ttf';

const Theme = () => {
	const appState: IState = store.getState();
	const { themeMode } = appState.app;

	return createTheme({
		typography: {
			fontFamily: 'Helvetica',
		},
		palette: {
			...(themeMode === 'light'
				? {
					primary: {
						main: '#4211CF'
					},
					secondary: {
						main: '#828282',
						light: '#C9C9C9',
						contrastText: '#E5E5E5'
					},
					error: {
						main: '#EB5757',
					},
					success: {
						main: '#27AE60',
						contrastText: '#219653'
					},
					warning: {
						main: '#F2994A'
					},
					claraPalette: {
						darkGray: '#1A1A1A',
						gray1: '#828282',
						gray2: '#BDBDBD',
						gray3: '#E0E0E0',
						gray4: '#F2F2F2',
						gray5: '#E5E5E5',
						gray6: '#333333'
					}
				}
				: {
					primary: {
						main: '#BDBDBD'
					}
				})
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: `
					@font-face {
						font-family: 'Helvetica';
						font-style: normal;
						font-weight: 400;
						font-display: swap;
						src: local('Helvetica'), url(${Helvetica}) format('truetype');
					}
					@font-face {
						font-family: 'Helvetica';
						font-style: normal;
						font-weight: 700;
						font-display: swap;
						src: local('Helvetica'), url(${HelveticaBold}) format('truetype');
					}
					@font-face {
						font-family: 'Helvetica';
						font-style: italic;
						font-weight: 400;
						font-display: swap;
						src: local('Helvetica'), url(${HelveticaItalic}) format('truetype');
					}
					@font-face {
						font-family: 'Helvetica';
						font-style: italic;
						font-weight: 700;
						font-display: swap;
						src: local('Helvetica'), url(${HelveticaBoldItalic}) format('truetype');
					}
					@font-face {
						font-family: 'Helvetica';
						font-style: normal;
						font-weight: 300;
						font-display: swap;
						src: local('Helvetica'), url(${HelveticaLight}) format('truetype');
					}
				`
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						margin: '0 1rem'
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '12px',
						textTransform: 'none',
						fontWeight: 700,
						'&.loading': {

						},

					},
					text: {
						color: 'black',
						':hover': {
							boxShadow: '0px 8px 9px -5px rgba(0, 0, 0, 0.2)'
						}
					}
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: '12px'
					}
				}
			},
			MuiSelect: {
				styleOverrides: {
					nativeInput: {
						borderBottom: 'none'
					},
					icon: {
						color: 'rgba(0, 0, 0, 0.26)'
					},
					select: {
						'&:focus':{
							backgroundColor:'white'
						  }
					},
					root: {
						'.required-asterisk': {
							color: '#EB5757',
							'::after': {
								'content': '"  *"'
							}
						}
					}
				}
			},
			MuiInput: {
				styleOverrides: {
					root: {
						'::before': {
							borderBottomStyle: 'dotted'
						}
					},
					input: {
						fontSize: '16px',
						lineHeight: '19px',
						fontWeight: 'normal',
						color:'#333333'
					}
				}
			},
			MuiInputLabel: {
				styleOverrides: {
					root: {
						fontcolor: 'rgba(0, 0, 0, 0.38)',
						'&.Mui-focused': {
							color: 'rgba(0, 0, 0, 0.38)'
						},
						'.required-asterisk': {
							color: '#EB5757',
							'::after': {
								'content': '"  *"'
							}
						}
					}
				}
			},
			MuiTypography: {
				styleOverrides: {
					root: {
						'&.em': {
							color: '#4211CF'
						},
						'.required-asterisk': {
							color: '#EB5757',
							'::after': {
								'content': '"  *"'
							}
						}
					},
					h1: {
						fontSize: '6rem'
					},
					h2: {
						fontSize: '3.75rem'
					},
					h3: {
						fontSize: '3rem'
					},
					h4: {
						fontSize: '2rem'
					},
					h5: {
						fontSize: '1.5rem'
					},
					h6: {
						fontSize: '1.25rem'
					},
					subtitle1: {
						fontSize: '1rem'
					},
					subtitle2: {
						fontSize: '0.875rem'
					},
					body1: {
						fontSize: '0.875rem'
					},
					body2: {
						fontSize: '0.75rem'
					}
				}
			}
		}
	});
};

export default Theme;

import { AxiosResponse } from 'axios';
import axiosInstance from '../../../../core/network';

export function getCreatorList(searchString:string, page) {
  const url = searchString.length >= 3 ? `users/user/creator${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `users/user/creator${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};
export function getSingleApplicant(userId:string,applicantId:string, opportunityId:string) {
  const url = `/opportunity/${userId}/applicant/${opportunityId}/${applicantId}`;
  return axiosInstance.get(url);
};

export function getBrandListAPI(searchString, page) {
  const url = searchString.length >= 3 ? `users/user/brand${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `users/user/brand${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};

export function updateUserStatusAPI(status, userId) {
  const url = `/users/update-status`;
  return axiosInstance.put(url, { status, userId });
};
export function updateReviewStatusAPI(status, userId, reviewId) {
  const url = `brand/:${userId}/update-review-status`;
  return axiosInstance.put(url, { status, reviewId });
};

export function deleteReviewAPI(userId, reviewId) {
  const url = `/brand/${userId}/${reviewId}`;
  return axiosInstance.delete(url);
};

export function getReviewsAPI(searchString, page, userId) {
  const url = searchString.length >= 3 ? `brand/${userId}/reviews/list${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `brand/${userId}/reviews/list${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};
export function getOpportunityAPI(searchString, page, userId) {
  const url = searchString.length >= 3 ? `opportunity/${userId}/opportunities/list/${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `opportunity/${userId}/opportunities/list/${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};

export function getPerksAPI(searchString, page, userId) {
  const url = searchString.length >= 3 ? `perks/${userId}/perks/list/${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `perks/${userId}/perks/list/${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};
export function updatePerkPinStatusApi(userId, isPin, perkId) {
  const url = `perks/${userId}/pin-perk`;
  return axiosInstance.put(url,{perkId,'pin':!isPin});
};

export function updatePinStatusApi(userId, isPin, opportunityId) {
  const url = `opportunity/${userId}/pin-opportunity`;
  return axiosInstance.put(url,{opportunityId,'pin':!isPin});
};

export function assignUserAPI(userId: string, brandId: string) {
  const url = `users/assign-brand`;
  return axiosInstance.put(url,{userId,brandId});
};
export function assignReviewAPI(reviewId: string, brandId: string, adminId: string) {
  const url = `brand/${adminId}/assign-review`;
  return axiosInstance.put(url, { 'id': reviewId, brandId });
};

export function exportCreatorAPI(): Promise<AxiosResponse<any>> {
  const url = `users/export-creators`;
  return axiosInstance.get(url);
};

export function uploadOpportunityImage(userId: string, body: {opportunityId:string, image: File}) {
  return axiosInstance.post(`/opportunity/${userId}/${body.opportunityId}/image`, body);
}

export function addOpportunityRequest(
  userId: string,
  body: any
) {
  return axiosInstance.post(`/opportunity/${userId}/add-opportunity`, body);
};

export function uploadPerkImage(userId: string, body: {perkId:string}) {
  return axiosInstance.post(`/perks/${userId}/${body.perkId}/image`, body);
};

export function addPerkRequest(
  userId: string,
  body: any
) {
  return axiosInstance.post(`/perks/${userId}/add-perk`, body);
};

export function getSinglePerkRequest(
  userId: string,
  perkId: string,
) {
  return axiosInstance.get(`/perks/${userId}/${perkId}`);
};

export function updatePerkRequest(
  userId: string,
  perkId:string,
  body: any
) {
  return axiosInstance.put(`/perks/${userId}/${perkId}`, body);
};

export function getSingleOpportunityRequest(
  userId: string,
  opportunityId: string,
) {
  return axiosInstance.get(`/opportunity/${userId}/${opportunityId}`);
};

export function updateOpportunityRequest(
  userId: string,
  opportunityId:string,
  body: any
) {
  return axiosInstance.put(`/opportunity/${userId}/${opportunityId}`, body);
};

export function getApplicantList(searchString:string, 
  userId:string,  opportunityId:string, page) {
  const url = searchString.length >= 3 ? `opportunity/${userId}/applicant-list/${opportunityId}${'?query=' + searchString + '&page=' + page + '&pageLimit=10'}` : `opportunity/${userId}/applicant-list/${opportunityId}${'?page=' + page + '&pageLimit=10'}`;
  return axiosInstance.get(url);
};

export function exportOpportunityApplicantsAPI(
  userId:string,id:string): Promise<AxiosResponse<any>> {
  const url = `/opportunity/${userId}/export-applicants/${id}`;
  return axiosInstance.get(url);
};

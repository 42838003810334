import React from 'react';

const UgcVideo = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">    
    <g>
      <path 
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.0245 8.73035L14.2366 7.45872C14.6568 7.21788 15 7.41584 15 7.90099V12.851C15 13.3338 14.6557 13.5319 14.2366 13.2909L12.0245 12.0193V12.4296C12.0245 12.7797 11.8854 13.1155 11.6378 13.363C11.3903 13.6105 11.0545 13.7496 10.7045 13.7496H5.31999C4.96994 13.7496 4.63419 13.6105 4.38665 13.363C4.13903 13.1155 4 12.7797 4 12.4296V8.31999C4 7.96994 4.13905 7.63419 4.38665 7.38665C4.63419 7.13911 4.96996 7 5.31999 7H10.7045C11.0545 7 11.3903 7.13911 11.6378 7.38665C11.8854 7.63419 12.0245 7.96996 12.0245 8.31999V8.73035Z" 
        fill={iconColor}
      />
    </g>
  </svg>
);

UgcVideo.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48',
  height: '48'
};

export default UgcVideo;
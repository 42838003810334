/* eslint-disable no-unused-vars */
export enum SessionKeys {
  accessToken = 'accessToken',
  userId = 'userId',
  brandId = 'brandId',
  refreshToken = 'refreshToken',
  accountStatus = 'accountStatus',
  accountType = 'accountType',
  userData = 'userData',
  otpExpireTime = 'otpExpireTime',
}

export enum AuthRoles {
  BRAND = 'BRAND',
  CREATOR = 'CREATOR',
  ADMIN = 'ADMIN'
}

export const ShareProfile = 'share';
export const RegisterType = 'register';

export enum AccountTypes {
  BRAND = 'BRAND',
  CREATOR = 'CREATOR'
}

export enum SocialMediaTypes {
  Tiktok = 'Tiktok',
  Instagram = 'Instagram',
  Youtube = 'Youtube',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Pinterest = 'Pinterest',
  Snapchat = 'Snapchat',
  Twitch = 'Twitch',
  Linkedin = 'Linkedin',
}

export type AccountType = 'CREATOR' | 'BRAND' | 'ADMIN';

export const FormikErrorMessages = {
  brandNameMsg : 'Brand Name should be 30 chars at most',
  requiredMsg : 'This field is required',
  titleOfPerkMsg : 'Title of Perk should be 100 chars at most',
  cityOfWorkMsg : 'City of Work should be 30 chars at most',
  titleOfOpportunity:  'Title of Opportunity should 75 chars at most',
  descriptionMsg: 'Description should be 3000 chars at most',
  detailsMsg: 'Details should be 3000 chars at most',
  location: 'location should be 100 chars at most',
  rate: 'rate should be 100 chars at most',
  referenceText: 'referenceText should be 200 chars at most',
  perkCode: 'perk code should be 9 chars at most'
};
import React from 'react';

const Facebook = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill={iconColor}
    />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3698 37.8515V23.9992H17.5547V19.4076H20.3698V16.6308C20.3698 12.8854 21.4897 10.1846 25.5905 10.1846H30.4691V14.7668H27.0339C25.3136 14.7668 24.9216 15.91 24.9216 17.1071V19.4076H30.2156L29.493 23.9992H24.9216V37.8515H20.3698Z"
        fill="white"
    />
  </svg>
);

Facebook.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48', 
  height: '48'
};

export default Facebook;

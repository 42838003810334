import { ReducerActionProps } from 'shared/utilities/interfaces';
import {
  CLAIM_CREATOR_PERK_FAILURE,
  CLAIM_CREATOR_PERK_SUCCESS,
  GET_CREATOR_PERK_DETAILS_FAILURE,
  GET_CREATOR_PERK_DETAILS_SUCCESS,
} from './action';

const initialState = {
  perkDetails: {},
  perkClaim:{},
};

const creatorPerkReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case GET_CREATOR_PERK_DETAILS_SUCCESS:
      return {
        ...state,
        perkDetails: action.payload,
        perkClaim: {}
      };
    case GET_CREATOR_PERK_DETAILS_FAILURE:
      return {
        ...state,
      };
    case CLAIM_CREATOR_PERK_SUCCESS:
      return {
        ...state,
        perkClaim: action.payload,
      };
    case CLAIM_CREATOR_PERK_FAILURE:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default creatorPerkReducer;

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CORE_ACTIONS } from '../../core/store/app/actions';
import store, { history } from '../../store/store';
import { SessionKeys } from '../constants';
import { clearLocalStorage, clearSessionStorage, getFromSession, setSessionItem } from '../../shared/helpers/auth.helper';
import { AUTH_ACTIONS } from '../../features/auth/store/actions';
import { getAlertType } from 'shared/helpers';
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BE,
});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.baseURL = process.env.REACT_APP_BASE_URL_BE;
    let tokenData;
    if(config.url === '/auth/refresh') {
      tokenData = getFromSession(SessionKeys.refreshToken);
    } else {
      tokenData = getFromSession(SessionKeys.accessToken);
    }
    if (tokenData.success) {
      const auth = `Bearer ${tokenData.result}`;
      config.headers['Authorization'] = auth;
    }

    store.dispatch({ type: CORE_ACTIONS.ENABLE_LOADER });
    return config;
  },
  (error: AxiosError) => {
    store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    // store.dispatch({
    //   type: CORE_ACTIONS.ENABLE_MODAL,
    //   payload: {
    //     message: 'err',
    //     severity: 'error',
    //     alertTitle: 'err',
    //     strongText: 'err'
    //   }
    // });
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((promise: any) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    return response;
  },
  (err: any) => {
    const originalRequest = err.config;
    // Skip refresh functionality for login api
    if(originalRequest.url == '/auth/login') {
      store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      return Promise.reject(err);
    }
    if (err?.response?.status === 403) {
      store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
      store.dispatch({ type: AUTH_ACTIONS.LOGOUT_REQUEST });
      history.replace('/auth');
      store.dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: err.response.data.message,
          severity: getAlertType(err.response.data.code),
          alertTitle: err.response.data.error,
          strongText: err.response.data.status
        }
      });
      return Promise.reject(err);
    }
    if (err?.response?.status === 401 && !originalRequest._retry) {
      if (originalRequest.url === '/auth/refresh') {
        processQueue(err, null);
        store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
        // store.dispatch({  });
        // type here should be session expired
        return Promise.reject(err);
      }
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            return axios(originalRequest);
          })
          .catch(isRefreshingError => {
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            return Promise.reject(isRefreshingError);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`/auth/refresh`)
          .then(({ data }: AxiosResponse) => {
            // set access token from response
            axiosInstance.defaults.headers.common.Authorization =
              `Bearer //accessToken`;
            originalRequest.headers.Authorization =
              `Bearer //accessToken`;
            processQueue(null, data.data.token);
            setSessionItem(SessionKeys.accessToken, data.data.token);
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            resolve(axiosInstance(originalRequest));
          })
          .catch((refreshErr: Error | AxiosResponse) => {
            processQueue(refreshErr, null);
            store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
            // dispatch session expired
            store.dispatch({
              type: AUTH_ACTIONS.SESSION_EXPIRED
            });
            // store.dispatch({
            //   type: CORE_ACTIONS.ENABLE_MODAL,
            //   payload: {
            //     message: 'Please login again',
            //     severity: 'error',
            //     alertTitle: 'Session expired'
            //   }
            // });
            clearSessionStorage();
            clearLocalStorage();
            store.dispatch({ type: AUTH_ACTIONS.LOGOUT_REQUEST });
            history.replace('/auth');
            reject(refreshErr);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    /* istanbul ignore else */
    // if (
    //   err?.response?.status === 401 &&
    //   err?.data?.errors[0]?.message === "The access token has been revoked."
    // &&
    //   err?.data?.errors[0]?.reason === "TokenRevokedError"
    // ) {
    //   return Promise.reject(err);
    // }

    store.dispatch({ type: CORE_ACTIONS.DISABLE_LOADER });
    if (err?.response?.data) {
      store.dispatch({
        type: CORE_ACTIONS.ENABLE_MODAL,
        payload: {
          message: err.response.data.message,
          severity: getAlertType(err.response.data.code),
          alertTitle: err.response.data.error,
          strongText: err.response.data.status
        }
      });
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;

import { AxiosResponse } from 'axios';
import axiosInstance from 'core/network';
export function getCreatorPerkRequest(
    userId: string,
    perkId: string,
  ): Promise<AxiosResponse<any>>  {
    return axiosInstance.get(`/perks/${userId}/${perkId}`);
  };

  
export function getCreatorPerkClaimRequest(
  userId: string,
  perkId: string,
): Promise<AxiosResponse<any>>  {
  return axiosInstance.get(`/perks/${userId}/claim/${perkId}`);
};
import React from 'react';

const Brand = ({ iconColor, ...props }) => (
  <svg
  {...props}
  viewBox='0 0 225 219'
  fill='none'
  xmlns='http://www.w3.org/2000/svg'
>
  <path
      d='M145.5 91.4996V91.2996C147 81.7996 137.8 80.9996 130.7 83.8996C127.6 84.8996 124.5 87.2996 121.2 86.7996C114.8 84.9996 117.5 72.3996 108.3 71.0996C95.3002 71.0996 97.8002 93.2996 83.8002 92.4996C80.9002 92.5996 77.7002 92.3996 74.9002 93.1996C68.2002 94.6996 68.0002 102.2 70.4002 107.4C71.9002 111.5 75.2002 114.9 76.2002 119C78.1002 127.8 66.7002 135.7 71.7002 144.4C76.1002 151 84.4002 146.9 90.2002 144.1C93.3002 142.6 97.1002 142.3 99.5002 145.3C101.5 147.5 102.9 150.6 105.1 152.6C107.8 155.4 112.3 155.9 115.2 153.1C119.4 149.7 119.6 142.7 124.1 139.6C127.3 137.7 131.9 139.6 135.5 139.7C141.5 140.1 145 137 145.3 131.1C145.9 121.9 139.3 113.6 141.3 104.5C142.2 99.9996 144.7 96.0996 145.5 91.4996Z'
      fill={iconColor}
  />
  <path
      d='M175.5 184.499C174.2 183.299 171.5 183.599 169.6 183.499C157.5 183.399 145.3 184.399 133.1 184.399C98.2 183.299 63.1 182.999 28.1 182.899C22.4 182.999 15 182.499 14.4 175.399C12.1 135.799 9.90003 96.0994 12.2 56.3994C13.1 50.9994 16.3 49.0994 21.6 48.3994C77.4 45.5994 133.5 46.1995 189.4 46.5995C194.8 46.7995 203.6 45.6995 204.6 52.7995C205.2 58.9995 205.2 65.2995 205.1 71.4995C204.4 100.499 202.2 129.499 203.6 158.499C203.5 161.499 204.9 164.199 207.8 165.199C209.9 165.999 212.9 167.399 214.2 166.799C217.2 164.699 214.6 153.299 215.1 149.399C213 103.399 218.6 57.2994 213.8 11.3994C212.4 3.49945 204.6 1.69945 197.6 1.09945C173.9 -1.40055 64 2.39946 32.8 3.49945C20.9 4.29946 -0.099969 1.99945 1.40003 19.4995C0.300031 73.8995 -1.49997 128.499 3.50003 182.799C4.50003 189.699 9.80003 193.299 16.5 194.199C61.8 194.499 107.2 196.699 152.6 194.999C160.1 194.499 168.2 195.599 175.5 194.199C178.2 193.099 177.2 186.799 175.5 184.499ZM56.3 20.9995L56.5 20.8994C60.6 18.6994 66.6 22.1995 64.9 27.0995C63.8 30.3995 59.5 32.1995 56.4 30.2995C52.9 28.3995 52.5 22.7995 56.3 20.9995ZM38.8 22.2995L38.9 22.1995C41.5 19.6995 47.1 19.7995 48.5 23.6995C49.9 27.7995 45.5 33.2994 41.3 31.8994C37.2 30.7994 35.7 25.0995 38.8 22.2995ZM26.9 21.1995H27.1C34.6 22.5995 30.5 33.8995 24 32.4995C16.6 30.2995 19.2 20.2995 26.9 21.1995Z'
      fill={iconColor}
  />
  <path
      d='M223.3 191.499C225.2 188.599 224.3 184.699 222.1 182.099L222 181.999C217.6 177.299 211.2 175.099 205.3 172.699C199.3 170.099 187.2 161.699 184 171.799C183 175.599 183.6 179.899 184.1 183.799C184.9 189.599 185.2 195.699 185.9 201.499C186.3 204.299 186.9 207.499 188.6 209.699C193.7 215.999 199.5 206.599 204.9 211.399C207.5 213.599 209.4 216.699 212.4 218.099C216.6 220.399 221.6 217.599 222.1 212.899C222.6 209.099 220.5 205.699 218.9 202.399C218 200.499 217.8 198.299 219.1 196.599C220.2 194.699 222.2 193.399 223.3 191.499Z'
      fill={iconColor}
  />
</svg>
);

Brand.defaultProps = {
  iconColor: 'white',
  width: '64',
  height: '64'
};

export default Brand;

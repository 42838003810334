import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { openRoutes, adminRoutes, brandRoutes, creatorRoutes } from './routes';
import { IState } from '../shared/utilities/interfaces';
import AppLoader from '../core/components/appLoader';
import { AccountStatusEnum, AccountType } from '../features/auth/utilites';
import { AuthRoles, SessionKeys } from '../core/constants';
import NotFound from '../core/components/notFound';
import { getFromSession } from '../shared/helpers/auth.helper';
import { history } from 'store/store';
interface IMainRoutes {
  isLoggedIn: boolean;
  accountType: AccountType;
  status: string;
}

const Routes = (routesProps: IMainRoutes) => {
  const { isLoggedIn, accountType, status } = routesProps;
  const searchParam = history.location.search;
  const isSharedProfile = searchParam.includes("?share=true") || false;
  if (isLoggedIn && status === AccountStatusEnum.APPROVED) {
    if (accountType === AuthRoles.BRAND) {
      const brandId = getFromSession(SessionKeys.brandId).result;
      return (
        <Switch>
          {brandRoutes.map((singleRoute) => {
            return (
              <Route
                component={singleRoute.component}
                exact={singleRoute.exact}
                path={singleRoute.path}
                key={singleRoute.path}
              />
            );
          })}
          <Route>
            <NotFound to={`/brand-profile/${brandId}`} navLinkName='brand profile' />
          </Route>
        </Switch>
      );
    } else if (accountType === AuthRoles.CREATOR) {
      return (
        <Switch>
          {creatorRoutes.map((singleRoute) => {
            return (
              <Route
                component={singleRoute.component}
                exact={singleRoute.exact}
                path={singleRoute.path}
                key={singleRoute.path}
              />
            );
          })}
          <Route>
            <NotFound />
          </Route>
        </Switch>
      );
    } else if (accountType === AuthRoles.ADMIN) {
      return (
        <Switch>
          {adminRoutes.map((singleRoute) => {
            return (
              <Route
                component={singleRoute.component}
                exact={singleRoute.exact}
                path={singleRoute.path}
                key={singleRoute.path}
              />
            );
          })}
          <Route>
          <NotFound to={"/manage-creators"} navLinkName='creator list'/>
          </Route>
        </Switch>
      );
    }

  } else if(isSharedProfile){
    const filteredRoutes = creatorRoutes.filter(singleRoute => singleRoute.path === "/creator-profile");
    return (
        <Switch>
              {filteredRoutes.map((singleRoute) => {
                return (
                  <Route
                    component={singleRoute.component}
                    exact={singleRoute.exact}
                    path={singleRoute.path}
                    key={singleRoute.path}
                  />
                );
              })}
                <Route>
                  <NotFound />
                </Route>
        </Switch>
    );
  } else {
    return (
      <Switch>
        <>
          {openRoutes.map((singleRoute) => {
            return (
              <Route
                component={singleRoute.component}
                exact={singleRoute.exact}
                path={singleRoute.path}
                key={singleRoute.path}
              />
            );
          })}
        </>
      </Switch>
    );
  }
};

const MainRoutes = (mainRoutesProps: IMainRoutes) => {
  const { isLoggedIn, accountType, status } = mainRoutesProps;
  const brandId = getFromSession(SessionKeys.brandId).result;
	const currentPath = history.location.pathname;
  const searchParam = history.location.search;
  const isSharedProfile = searchParam.includes("?share=true");
  return (
    <Suspense fallback={<AppLoader />}>
      <Switch>
        <Route path="/" exact={true}>
          {
          isSharedProfile && !isLoggedIn ? <Redirect to="/creator-profile" />
          : !isLoggedIn 
          && !currentPath.includes('update-password') 
          && !currentPath.includes("reset-password") 
          && !currentPath.includes("verify-otp") 
          && !currentPath.includes('auth/signup')
          && currentPath !== '/terms-and-conditions' 
          && currentPath !== '/privacy-policy' 
          && currentPath !== '/community-guidelines' 
          && currentPath !== '/do-not-sell-my-info' 
          && !searchParam.includes("?account-type=CREATOR")  
          && !searchParam.includes("?account-type=BRAND") ? (
            history.push('/auth')
          ) : (
            accountType === AuthRoles.CREATOR ?   <Redirect exact from={"/"} to="/dashboard" />  : 
            accountType === AuthRoles.BRAND ?   <><Redirect exact from={"/"} to={"/brand-profile/"+ brandId } /></> :
            accountType === AuthRoles.ADMIN ?   <><Redirect exact from={"/"} to="/manage-creators" /></> :
            <NotFound />
          )}
        </Route>
          <Routes
            isLoggedIn={isLoggedIn}
            accountType={accountType}
            status={status}
          />
      </Switch>
    </Suspense>
  );
};

export default connect((state: IState) => ({
  isLoggedIn: state.auth.isLoggedIn,
  accountType: state.auth.accountType,
  status: state.auth.status
}))(MainRoutes);
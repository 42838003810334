import { ReducerActionProps } from 'shared/utilities/interfaces';
import {
  APPLY_CREATOR_OPPORTUNITY_FAILURE,
  APPLY_CREATOR_OPPORTUNITY_SUCCESS,
  GET_CREATOR_OPPORTUNITY_DETAILS_FAILURE,
  GET_CREATOR_OPPORTUNITY_DETAILS_SUCCESS,
} from './action';

const initialState = {
  opportunityDetails: {},
};

const creatorOpportunityReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case GET_CREATOR_OPPORTUNITY_DETAILS_SUCCESS:
      return {
        ...state,
        opportunityDetails: action.payload,
      };
    case GET_CREATOR_OPPORTUNITY_DETAILS_FAILURE:
      return {
        ...state,
      };
    case APPLY_CREATOR_OPPORTUNITY_SUCCESS:
      return {
        ...state,
      };
    case APPLY_CREATOR_OPPORTUNITY_FAILURE:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default creatorOpportunityReducer;

import { ReducerActionProps } from '../../../../shared/utilities/interfaces';
import {
  GET_ADMIN_DASHBOARD_DATA,
  GET_CREATOR_LIST,
  GET_CREATOR_LIST_FAILURE,
  SET_USERS_COUNT,
  GET_BRAND_LIST,
  GET_BRAND_LIST_FAILURE,
  SET_BRAND_COUNT,
  GET_REVIEW_LIST,
  SET_REVIEW_COUNT,
  GET_REVIEW_LIST_FAILURE,
  GET_OPPORTUNITY_LIST,
  GET_OPPORTUNITY_LIST_FAILURE,
  SET_OPPORTUNITY_COUNT,
  GET_OPPORTUNITY_APPLICANTS,
  GET_OPPORTUNITY_APPLICANTS_FAILURE,
  SET_OPPORTUNITY_IMAGE_SUCCESS,
  RESET_OPPORTUNITY_IMAGE_SUCCESS,
  ADD_OPPORTUNITY_DETAILS_SUCCESS,
  ADD_OPPORTUNITY_DETAILS_FAILURE,
  SET_PERK_IMAGE_SUCCESS,
  RESET_PERK_IMAGE_SUCCESS,
  ADD_PERK_DETAILS_SUCCESS,
  ADD_PERK_DETAILS_FAILURE,
  GET_SINGLE_PERK_DETAILS_SUCCESS,
  GET_SINGLE_PERK_DETAILS_FAILURE,
  UPDATE_SINGLE_PERK_DETAILS_SUCCESS,
  UPDATE_SINGLE_PERK_DETAILS_FAILURE,
  GET_PERKS_LIST,
  GET_PERKS_LIST_FAILURE,
  SET_PERKS_COUNT,
  GET_SINGLE_APPLICANT_DETAILS_SUCCESS,
  GET_SINGLE_APPLICANT_DETAILS_FAILURE,
  GET_SINGLE_OPPORTUNITY_DETAILS_SUCCESS,
  GET_SINGLE_OPPORTUNITY_DETAILS_FAILURE,
  UPDATE_SINGLE_OPPORTUNITY_DETAILS_SUCCESS,
  UPDATE_SINGLE_OPPORTUNITY_DETAILS_FAILURE,
} from './actions';

const initialState = {
  // add initial state variables
  userCount: 0,
  creatorList: [],
  opportunityApplicantsList: [],
  brandCount: 0,
  keys:[],
  brandList: [],
  reviewCount: 0,
  reviewList: [],
  opportunityCount: 0,
  opportunityList: [],
  perksCount: 0,
  perksList: [],
  addOpportunityImg: "",
  addPerkImg: "",
  singlePerkInfo: {},
  singleApplicantInfo: {},
  singleOpportunityInfo: {},
  defaultList: {
    data: [
      {
        ID: '87346734',
        Date: '22-09-2021',
        Amount: '1,780',
        Action: '',
      },
      {
        ID: '6782364',
        Date: '22-09-2021',
        Amount: '1,780',
        Action: '',
      },
      {
        ID: '7986732',
        Date: '22-09-2021',
        Amount: '1,780',
        Action: '',
      }
    ]
  }
};

const adminDashboardReducer = (
  state = initialState,
  action: ReducerActionProps
) => {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD_DATA:
      return {
        ...state,
        ...action.payload
      };

    case GET_CREATOR_LIST:
      return {
        ...state,
        creatorList: action.payload,
        keys: action.payload.keys
      };

    case GET_CREATOR_LIST_FAILURE:
      return {
        ...state,
        creatorList: []
      };

    case SET_USERS_COUNT:
      return {
        ...state,
        userCount: action.payload,
      };

    case GET_OPPORTUNITY_APPLICANTS:
      return {
        ...state,
        opportunityApplicantsList: action.payload,
        keys: action.payload.keys
      };

      case GET_OPPORTUNITY_APPLICANTS_FAILURE:
        return {
          ...state,
          creatorList: []
        };
    
    case GET_BRAND_LIST:
      return {
        ...state,
        brandList: action.payload.data,
        keys: action.payload.keys
      };  
      
    case GET_BRAND_LIST_FAILURE:
      return {
        ...state,
        brandList: []
      };

    case SET_BRAND_COUNT:
      return {
        ...state,
        brandCount: action.payload,
      };
    case GET_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload,
        keys: action.payload.keys

      };

    case GET_REVIEW_LIST_FAILURE:
      return {
        ...state,
        reviewList: []
      };

    case SET_REVIEW_COUNT:
      return {
        ...state,
        reviewCount: action.payload,
      };
    case GET_OPPORTUNITY_LIST:
      return {
        ...state,
        opportunityList: action.payload,
        keys: action.payload.keys

      };

    case GET_SINGLE_PERK_DETAILS_SUCCESS:
       return {
        ...state,
        addPerkImg: action.payload.brandLogo,
        singlePerkInfo: action.payload,  
      };

      case GET_SINGLE_OPPORTUNITY_DETAILS_SUCCESS:
        return {
         ...state,
         addOpportunityImg: action.payload.brandLogo,
         singleOpportunityInfo: action.payload,  
       };

      case GET_SINGLE_PERK_DETAILS_FAILURE:
        return {
         ...state,
         singlePerkInfo: {},  
         addPerkImg: ''
       };

       case GET_SINGLE_OPPORTUNITY_DETAILS_FAILURE:
        return {
         ...state,
         addOpportunityImg: "",
         singleOpportunityInfo: {},  
       };
    case GET_SINGLE_APPLICANT_DETAILS_SUCCESS:
       return {
        ...state,
        singleApplicantInfo: action.payload,  
      };

      case GET_SINGLE_APPLICANT_DETAILS_FAILURE:
        return {
         ...state,
         singleApplicantInfo: {},  
       };

    case GET_OPPORTUNITY_LIST_FAILURE:
      return {
        ...state,
        opportunityList: []
      };

    case SET_OPPORTUNITY_COUNT:
      return {
        ...state,
        opportunityCount: action.payload,
      };
    
      case GET_PERKS_LIST:
      return {
        ...state,
        perksList: action.payload,
        keys: action.payload.keys
      };
    case GET_PERKS_LIST_FAILURE:
      return {
        ...state,
        perksList: []
      };
    case SET_PERKS_COUNT:
      return {
        ...state,
        perksCount: action.payload,
      };
    
    case SET_OPPORTUNITY_IMAGE_SUCCESS:
      return {
        ...state,
        addOpportunityImg: action.payload,
      };  

      case RESET_OPPORTUNITY_IMAGE_SUCCESS:
        return {
          ...state,
          addOpportunityImg: ""
      }; 

      case SET_PERK_IMAGE_SUCCESS:
        return {
          ...state,
          addPerkImg: action.payload,
        };  
      
      case RESET_PERK_IMAGE_SUCCESS:
          return {
            ...state,
            addPerkImg: ""
        }; 

      case ADD_OPPORTUNITY_DETAILS_SUCCESS:
        return {
          ...state,
          ...action.payload
      }; 

      case UPDATE_SINGLE_PERK_DETAILS_SUCCESS:
        return {
          ...state,
      }; 
      
      case UPDATE_SINGLE_PERK_DETAILS_FAILURE:
        return {
          ...state,
      }; 

      case UPDATE_SINGLE_OPPORTUNITY_DETAILS_SUCCESS:
        return {
          ...state,
      }; 
      
      case UPDATE_SINGLE_OPPORTUNITY_DETAILS_FAILURE:
        return {
          ...state,
      }; 
      
      case ADD_PERK_DETAILS_SUCCESS:
        return {
          ...state,
          ...action.payload
      }; 

      case ADD_OPPORTUNITY_DETAILS_FAILURE: 
      return {
        ...state
      };

      case ADD_PERK_DETAILS_FAILURE: 
      return {
        ...state
      };

    default:
      return {
        ...state
      };
  }
};

export default adminDashboardReducer;

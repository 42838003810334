import React from 'react';

const WebIcon = ({ color, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 16.875C7.44248 16.875 5.91992 16.4131 4.62489 15.5478C3.32985 14.6825 2.32049 13.4526 1.72445 12.0136C1.12841 10.5747 0.972461 8.99127 1.27632 7.46367C1.58018 5.93607 2.3302 4.53288 3.43154 3.43154C4.53288 2.3302 5.93607 1.58018 7.46367 1.27632C8.99127 0.972461 10.5747 1.12841 12.0136 1.72445C13.4526 2.32049 14.6825 3.32985 15.5478 4.62489C16.4131 5.91992 16.875 7.44248 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 2.25C7.66498 2.25 6.35994 2.64588 5.2499 3.38758C4.13987 4.12928 3.27471 5.18349 2.76382 6.41689C2.25293 7.65029 2.11925 9.00749 2.3797 10.3169C2.64015 11.6262 3.28303 12.829 4.22703 13.773C5.17104 14.717 6.37377 15.3599 7.68314 15.6203C8.99252 15.8808 10.3497 15.7471 11.5831 15.2362C12.8165 14.7253 13.8707 13.8601 14.6124 12.7501C15.3541 11.6401 15.75 10.335 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25Z" fill={color}/>
    <path d="M9 16.875C8.85082 16.875 8.70774 16.8157 8.60225 16.7102C8.49676 16.6048 8.4375 16.4617 8.4375 16.3125V1.6875C8.4375 1.53832 8.49676 1.39524 8.60225 1.28975C8.70774 1.18426 8.85082 1.125 9 1.125C9.14918 1.125 9.29226 1.18426 9.39775 1.28975C9.50324 1.39524 9.5625 1.53832 9.5625 1.6875V16.3125C9.5625 16.4617 9.50324 16.6048 9.39775 16.7102C9.29226 16.8157 9.14918 16.875 9 16.875Z" fill={color}/>
    <path d="M12.9375 9.5625C12.7883 9.5625 12.6452 9.50324 12.5398 9.39775C12.4343 9.29226 12.375 9.14918 12.375 9C12.375 5.34375 10.8281 2.25 9 2.25C8.85082 2.25 8.70774 2.19074 8.60225 2.08525C8.49676 1.97976 8.4375 1.83668 8.4375 1.6875C8.4375 1.53832 8.49676 1.39524 8.60225 1.28975C8.70774 1.18426 8.85082 1.125 9 1.125C11.5256 1.125 13.5 4.58438 13.5 9C13.5 9.14918 13.4407 9.29226 13.3352 9.39775C13.2298 9.50324 13.0867 9.5625 12.9375 9.5625Z" fill={color}/>
    <path d="M9 16.875C8.85082 16.875 8.70774 16.8157 8.60225 16.7102C8.49676 16.6048 8.4375 16.4617 8.4375 16.3125C8.4375 16.1633 8.49676 16.0202 8.60225 15.9148C8.70774 15.8093 8.85082 15.75 9 15.75C10.8281 15.75 12.375 12.6562 12.375 9C12.375 8.85082 12.4343 8.70774 12.5398 8.60225C12.6452 8.49676 12.7883 8.4375 12.9375 8.4375C13.0867 8.4375 13.2298 8.49676 13.3352 8.60225C13.4407 8.70774 13.5 8.85082 13.5 9C13.5 13.4156 11.5256 16.875 9 16.875Z" fill={color}/>
    <path d="M5.0625 9.5625C4.91332 9.5625 4.77024 9.50324 4.66475 9.39775C4.55926 9.29226 4.5 9.14918 4.5 9C4.5 4.58438 6.47438 1.125 9 1.125C9.14918 1.125 9.29226 1.18426 9.39775 1.28975C9.50324 1.39524 9.5625 1.53832 9.5625 1.6875C9.5625 1.83668 9.50324 1.97976 9.39775 2.08525C9.29226 2.19074 9.14918 2.25 9 2.25C7.17188 2.25 5.625 5.34375 5.625 9C5.625 9.14918 5.56574 9.29226 5.46025 9.39775C5.35476 9.50324 5.21168 9.5625 5.0625 9.5625Z" fill={color}/>
    <path d="M9 16.875C6.47438 16.875 4.5 13.4156 4.5 9C4.5 8.85082 4.55926 8.70774 4.66475 8.60225C4.77024 8.49676 4.91332 8.4375 5.0625 8.4375C5.21168 8.4375 5.35476 8.49676 5.46025 8.60225C5.56574 8.70774 5.625 8.85082 5.625 9C5.625 12.6562 7.17188 15.75 9 15.75C9.14918 15.75 9.29226 15.8093 9.39775 15.9148C9.50324 16.0202 9.5625 16.1633 9.5625 16.3125C9.5625 16.4617 9.50324 16.6048 9.39775 16.7102C9.29226 16.8157 9.14918 16.875 9 16.875Z" fill={color}/>
    <path d="M16.3125 9.5625H1.6875C1.53832 9.5625 1.39524 9.50324 1.28975 9.39775C1.18426 9.29226 1.125 9.14918 1.125 9C1.125 8.85082 1.18426 8.70774 1.28975 8.60225C1.39524 8.49676 1.53832 8.4375 1.6875 8.4375H16.3125C16.4617 8.4375 16.6048 8.49676 16.7102 8.60225C16.8157 8.70774 16.875 8.85082 16.875 9C16.875 9.14918 16.8157 9.29226 16.7102 9.39775C16.6048 9.50324 16.4617 9.5625 16.3125 9.5625Z" fill={color}/>
    <path d="M15.1875 6.1875H2.8125C2.66332 6.1875 2.52024 6.12824 2.41475 6.02275C2.30926 5.91726 2.25 5.77418 2.25 5.625C2.25 5.47582 2.30926 5.33274 2.41475 5.22725C2.52024 5.12176 2.66332 5.0625 2.8125 5.0625H15.1875C15.3367 5.0625 15.4798 5.12176 15.5852 5.22725C15.6907 5.33274 15.75 5.47582 15.75 5.625C15.75 5.77418 15.6907 5.91726 15.5852 6.02275C15.4798 6.12824 15.3367 6.1875 15.1875 6.1875Z" fill={color}/>
    <path d="M15.1875 12.9375H2.8125C2.66332 12.9375 2.52024 12.8782 2.41475 12.7727C2.30926 12.6673 2.25 12.5242 2.25 12.375C2.25 12.2258 2.30926 12.0827 2.41475 11.9773C2.52024 11.8718 2.66332 11.8125 2.8125 11.8125H15.1875C15.3367 11.8125 15.4798 11.8718 15.5852 11.9773C15.6907 12.0827 15.75 12.2258 15.75 12.375C15.75 12.5242 15.6907 12.6673 15.5852 12.7727C15.4798 12.8782 15.3367 12.9375 15.1875 12.9375Z" fill={color}/>
  </svg>
);

WebIcon.defaultProps = {
  color: 'white',
  width: '48',
  height: '48'
};

export default WebIcon;

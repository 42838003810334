import React from 'react';

const Twitch = ({ iconColor, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">    
    <g>
      <path 
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM4.48047 6.35523L5.27547 4.24023H15.5205V11.3052L12.498 14.3202H10.338L8.89797 15.7602H7.12047V14.3202H4.48047V6.35523ZM14.3205 5.44023H6.64047V12.1602H8.56047V13.6002L10.0005 12.1602H12.8805L14.3205 10.7202V5.44023ZM8.80047 10.4802V7.12023H10.2405V10.4802H8.80047ZM11.2005 10.4802V7.12023H12.6405V10.4802H11.2005Z" 
        fill={iconColor}
      />
    </g>
  </svg>
);

Twitch.defaultProps = {
  iconColor: "#1A1A1AFF",
  width: '48',
  height: '48'
};

export default Twitch;